/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { Media } from '~utils'

export const StyledIframeContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  iframe {
    width: 100%;
    height: 100%;
    margin: 0px;
    ${Media({
      minHeight: ['90vh', null, null, '70vh']
    })}
  }
`
