import React, { useState } from 'react'
//
import { ContentfulBaristaColors, ContentfulRecipeContentfulTechniqueUnion } from '~generated/gatsby.types'
import theme from '~theme'
import { baristaReadableButtonTheme } from '~utils/colors'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import CTA from '../../base-components/cta'
import { StyledContainer, StyledTitle, StyledDescription } from './Styled'
import Carousel, { ArrowNavigationButtons } from '../../carousel'
import { withCard } from '../../base-components/card'

type Props = {
  id: string
  title?: string | null | undefined
  backgroundColor?: ContentfulBaristaColors | null | undefined
  bgColorOverride?: string | null | undefined
  shortDescription?: any | undefined
  cta?: any | null | undefined
  contents?: Array<ContentfulRecipeContentfulTechniqueUnion> | null | undefined
  articleSizeOverride?: boolean
}

const ContentCarousel = ({
  id,
  backgroundColor,
  bgColorOverride,
  title,
  shortDescription,
  cta,
  contents,
  articleSizeOverride
}: Props) => {
  if (!contents || !contents.length) {
    return null
  }

  const [carouselState, setCarouselState] = useState({})

  const bgColor = bgColorOverride || backgroundColor?.colorCode || theme.colors.oatmealBrown

  const description = shortDescription && shortDescription[Object.keys(shortDescription)[0]]

  const Card = withCard({ ctaTextId: 'contentCarousel.cta', backgroundColor: bgColor })

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer backgroundColor={bgColor}>
        <Grid>
          <Cell cols={[12, null, 9, 6, 5]} offset={[null, null, 1]}>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
          </Cell>
        </Grid>
        <Grid>
          <Cell cols={[12, null, 8]} offset={[null, null, 1]}>
            {cta && <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} fullWidth />}
          </Cell>
          <Cell cols={[0, null, 2]}>
            <ArrowNavigationButtons id={id} carouselState={carouselState} backgroundColor={bgColor} />
          </Cell>
        </Grid>
        <Carousel
          id={id}
          contents={contents}
          backgroundColor={bgColor}
          Card={Card}
          onChange={setCarouselState}
          articleSize={articleSizeOverride}
        />
      </StyledContainer>
    </>
  )
}

export default ContentCarousel
