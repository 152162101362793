import styled from 'styled-components'
//
import { Media } from '~utils'
import { getColumnWidth } from '../../base-components/grid'

export const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.mustard};
  overflow: hidden;

  ${Media({
    padding: ['28px 0 38px', null, '28px 0 88px', '80px 0 108px']
  })}
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Media({
    alignItems: [null, null, null, 'center', 'flex-start']
  })}
`

export const StyledImageContainer = styled.div`
  ${Media({
    marginBottom: ['56px', null, null, '0'],
    width: ['100%', null, null, '43%', 'auto'],
    flex: [null, null, null, null, '4']
  })}
`

export const StyledBaristaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media({
    padding: [null, null, null, '0 0 0 80px', '7% 80px 0'],
    width: ['100%', null, null, '57%', 'auto'],
    flex: [null, null, null, null, '3']
  })}
`

export const StyledDescription = styled.div`
  border-top: 1px solid ${props => props.theme.colors.black};
  display: flex;
  justify-content: center;

  ${Media({
    borderWidth: ['1px', null, null, null, '0'],
    marginTop: ['40px', null, null, '48px', '0'],
    paddingTop: ['40px', null, null, '48px', '7%'],
    width: ['100%', null, null, null, 'auto'],
    flex: [null, null, null, null, '2']
  })}
`

export const StyledDescriptionInner = styled.div`
  ${Media({
    width: ['100%', null, null, '80%', 'auto']
  })}
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.primary};

  ${Media({
    margin: ['0 0 24px', null, null, '0 0 16px']
  })}
`

export const StyledBaristaName = styled.p`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.primary};

  ${Media({
    margin: ['0 0 8px', null, null, '0 0 16px']
  })}
`

export const StyledPrefix = styled.p`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  color: ${props => props.theme.colors.primary};

  ${Media({
    margin: ['0 0 24px', null, null, '0 0 16px']
  })}
`

export const StyledQuote = styled.p`
  font-weight: normal;
  text-align: center;
  color: ${props => props.theme.colors.primary};

  ${Media({
    fontSize: ['24px', null, '40px', '50px'],
    lineHeight: ['30px', null, '50px', '60px'],
    letterSpacing: ['0.86px', null, '1px', '1.79px'],
    margin: ['0 0 42px', null, null, '0 0 30px', '0 0 50px'],
    width: [null, null, '80%', 'auto']
  })}
`

export const StyledSignature = styled.p`
  transform: rotate(-9deg);
  font-family: Caveat;
  color: ${props => props.theme.colors.black};
  text-align: center;
  margin-bottom: 50px;

  ${Media({
    fontSize: ['40px', null, null, '50px', '55px'],
    letterSpacing: ['-0.89px', null, null, '-1.11px', '-1.22px']
  })}
`

export const StyledLocation = styled.p`
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.black};
  margin: 0;
`

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${Media({
    marginBottom: ['40px', null, null, '0'],
    minHeight: ['auto', null, null, '800px'],
    maxHeight: ['416px', null, null, 'auto']
  })}
`

export const StyledMediaInner = styled.div`
  height: 100%;

  ${Media({
    position: ['relative', null, null, 'absolute'],
    width: ['calc(100vw - 1.5rem)', null, `calc(100vw - ${getColumnWidth(1)}vw - 0.75rem)`, 'calc(50vw - 0.75rem)'],
    overflow: ['visible', null, 'hidden'],
    left: ['0'],
    right: ['auto']
  })}

  .gatsby-image-wrapper {
    height: 100%;
  }
`
