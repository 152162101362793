import styled from 'styled-components'
//
import { Media } from '~utils'

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${Media({
    height: ['auto', null, null, '690px'],
    maxHeight: ['350px', '690px', '690px', '690px']
  })}
`

export const StyledMediaInner = styled.div`
  ${Media({
    position: ['relative', null, null, 'absolute'],
    height: ['auto', null, null, '100%'],
    width: ['100vw', null, null, '100vw'],
    overflow: ['visible', null, null, 'hidden']
  })}

  .gatsby-image-wrapper {
    ${Media({
      height: ['auto', null, null, '100%']
    })}
  }
`
