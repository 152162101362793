import styled from 'styled-components'
//
import { Media } from '~utils'
import { getColumnWidth } from '../../base-components/grid'
import { baristaReadableColor } from '~utils/colors'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  ${Media({
    padding: ['0 0 32px', null, '42px 0 54px', '120px 0 112px']
  })}
`

interface StyledTitleProps {
  backgroundColor: string
}

export const StyledTitle = styled.h2<StyledTitleProps>`
  font-weight: normal;
  letter-spacing: 1px;
  text-align: center;
  white-space: pre-wrap;
  color: ${props => baristaReadableColor(props.backgroundColor)};

  ${Media({
    fontSize: ['40px', null, null, null, '60px'],
    lineHeight: ['50px', null, null, null, '70px'],
    margin: ['0 0 24px', null, null, null, '0 0 32px']
  })}
`

export const StyledContentContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;

  ${Media({
    flexDirection: ['column', null, null, 'row']
  })}
`

interface StyledImageRowProps {
  align: string
}

export const StyledImageRow = styled.div<StyledImageRowProps>`
  flex: 1;
  display: flex;
  justify-content: ${props => (props.align === 'right' ? 'flex-end' : 'flex-start')};

  ${props =>
    Media({
      width: ['100%', null, null, 'auto'],
      order: [props.align === 'right' ? 1 : 3, null, props.align === 'right' ? 3 : 1]
    })}
`

export const StyledImageContainer = styled.div`
  ${Media({
    width: ['60%', null, '25%', 'auto'],
    flex: ['none', null, null, '1']
  })}
`

export const StyledTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  order: 2;

  ${Media({
    width: ['100%', null, `${getColumnWidth(10)}%`, 'auto'],
    margin: [null, null, `0 0 0 ${getColumnWidth(1)}%`, '0 90px'],
    paddingTop: ['40px', '48px', null, '0'],
    paddingBottom: ['40px', '48px', null, '0']
  })}
`
