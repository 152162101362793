import styled from 'styled-components'
//
import { Media } from '~utils'
import { baristaReadableColor } from '~utils/colors'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};

  color: ${props => baristaReadableColor(props.backgroundColor)};

  ${Media({
    paddingTop: ['48px', null, null, '85px'],
    paddingBottom: ['48px', null, '0']
  })}
`

export const StyledTitle = styled.h2`
  font-weight: normal;

  ${Media({
    fontSize: ['32px', null, null, '48px'],
    margin: ['0 0 24px', null, null, '0 0 32px']
  })}
`

export const StyledShortDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 24px;
  white-space: pre-wrap;

  ${Media({
    maxWidth: ['none', null, null, '480px']
  })}
`
