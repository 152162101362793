import React from 'react'

const Facebook = () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
    <g id="facebook-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="share-facebook" transform="translate(-56.000000, -13.000000)" fill="#242424" fillRule="nonzero">
        <path
          d="M63.4687322,26.9999976 L63.4687322,21.5625106 L61.6562365,21.5625106 L61.6562365,19.4687656 L63.4687322,19.4687656 L63.4687322,17.9062693 C63.4687322,17.0104379 63.7187316,16.3229396 64.2187304,15.8437742 C64.7187292,15.3646088 65.3749776,15.1250259 66.1874757,15.1250259 C66.8333077,15.1250259 67.3749729,15.1458594 67.8124718,15.1875258 L67.8124718,17.0937712 L66.6874745,17.0937712 C66.291642,17.0937712 66.0103926,17.187521 65.8437265,17.3750206 C65.7187268,17.5208537 65.656227,17.7708531 65.656227,18.1250188 L65.656227,19.4687656 L67.749972,19.4687656 L67.4687227,21.5625106 L65.656227,21.5625106 L65.656227,26.9999976 L69.2187185,26.9999976 C69.4478844,26.9999976 69.635384,26.9218728 69.7812171,26.7656232 C69.9270503,26.6093735 69.9999666,26.4270825 69.9999666,26.2187495 L69.9999666,13.7812791 C69.9999666,13.5521132 69.9218418,13.3646136 69.7655922,13.2187805 C69.6093426,13.0729473 69.4270515,13.000031 69.2187185,13.000031 L56.7812481,13.000031 C56.5520822,13.000031 56.3645826,13.0729473 56.2187495,13.2187805 C56.0729163,13.3646136 56,13.5521132 56,13.7812791 L56,26.2187495 C56,26.4270825 56.0729163,26.6093735 56.2187495,26.7656232 C56.3645826,26.9218728 56.5520822,26.9999976 56.7812481,26.9999976 L63.4687322,26.9999976 Z"
          id="f"
        />
      </g>
    </g>
  </svg>
)

export default Facebook
