/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react'
//
import { ContentfulInstagramBlock } from '~generated/gatsby.types'
import RoughEdge from '~components/base-components/rough-edge'
import { Grid, Cell } from '~components/base-components/grid'
import CTA from '~components/base-components/cta'
import { StyledContainer, StyledContent, StyledTitle, StyledShortDescription, StyledInstagramWrapper } from './Styled'
import { baristaReadableButtonTheme } from '~utils/colors'
import useScript from '~utils/external-script'

const InstagramBlock = ({ id, title, shortDescription, cta, flowboxId, backgroundColor }: ContentfulInstagramBlock) => {
  if (!flowboxId) {
    return null
  }

  const status = useScript('//connect.getflowbox.com/flowbox.js')

  const [loaded, setLoaded] = React.useState(false)

  const loadFlowboxScript = (): void => {
    // @ts-ignore
    if (window.flowbox) {
      // @ts-ignore
      // eslint-disable-next-line
      window.flowbox('init', {
        container: `#flowbox-${id}`,
        key: flowboxId,
        locale: 'en-GB'
      })
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    if (status === 'ready' && !loaded) {
      window.setTimeout(() => {
        // @ts-ignore
        if (window.flowbox) {
          loadFlowboxScript()
        } else {
          window.setTimeout(() => {
            loadFlowboxScript()
          }, 500)
        }
      }, 200)
    }
  }, [status])

  const bgColor = backgroundColor?.colorCode || '#ede9e6'

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer key={`component-${id}`} backgroundColor={bgColor}>
        <Grid>
          <Cell cols={[12, null, null, null, 10]} offset={[null, null, null, null, 1]}>
            <StyledContent backgroundColor={bgColor}>
              <StyledTitle>{title}</StyledTitle>
              <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>
              {cta && <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} />}
              <StyledInstagramWrapper id={`flowbox-${id}`} />
            </StyledContent>
          </Cell>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default InstagramBlock
