import React, { useState, useEffect } from 'react'
import Chevron from './Chevron'
import { StyledNavigationButton } from './Styled'
import { useResize } from '~utils/hooks'

type useNavigationButtonPositionProps = {
  containerRef: any
  swiperState: any
}

const useNavigationButtonPosition = ({ containerRef, swiperState }: useNavigationButtonPositionProps) => {
  const [position, setPosition] = useState(0)
  const windowDimensions = useResize()

  useEffect(() => {
    if (containerRef.current) {
      const image = containerRef.current.querySelector('img')
      const height = image?.clientHeight || 0

      setPosition(height / 2)
    }
  }, [containerRef, swiperState, windowDimensions])

  return position
}

type NavigationButtonProps = {
  id: string
  backgroundColor: string
  disabled: boolean
  direction: string
  containerRef: any
  swiperState: any
}

const NavigationButton = ({
  id,
  backgroundColor,
  disabled,
  direction,
  containerRef,
  swiperState
}: NavigationButtonProps) => {
  const navigationTopPosition = useNavigationButtonPosition({ containerRef, swiperState })

  return (
    <StyledNavigationButton
      backgroundColor={backgroundColor}
      direction={direction}
      id={`${direction}-navigation-button-${id}`}
      disabled={disabled}
      style={{ top: navigationTopPosition }}
    >
      <Chevron direction={direction} />
    </StyledNavigationButton>
  )
}

export default NavigationButton
