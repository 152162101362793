import styled from 'styled-components'
//
import { Media } from '~utils'
import { getColumnWidth } from '../../base-components/grid'
import { baristaReadableColor } from '~utils/colors'
import Button from '../../base-components/button'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  overflow: hidden;

  ${Media({
    padding: ['48px 0', null, null, '85px 0']
  })}
`

interface StyledContentProps {
  backgroundColor: string
}

export const StyledContent = styled.div<StyledContentProps>`
  color: ${props => baristaReadableColor(props.backgroundColor)};

  ${Media({
    minHeight: ['none', null, null, '800px']
  })}
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const StyledTitle = styled.h1`
  font-weight: normal;
  margin: 0 0 24px;
  outline: 0;

  ${Media({
    fontSize: ['40px', null, null, '60px'],
    lineHeight: ['50px', null, null, '70px']
  })}
`

export const StyledShortDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 24px;
  white-space: pre-wrap;

  ${Media({
    maxWidth: ['none', null, null, '480px']
  })}
`

interface StyledLabelProps {
  isSelected?: boolean
  selectedBgColor: string
  selectedFgColor: string
}

export const StyledLabel = styled.label<StyledLabelProps>`
  display: block;
  height: 55px;
  margin-bottom: 24px;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? props.selectedBgColor : 'transparent')};
  color: ${props => (props.isSelected ? props.selectedFgColor : 'inherit')};
  text-align: center;

  ${Media({
    maxWidth: ['none', null, null, '495px']
  })}
`

export const StyledInput = styled.input`
  display: none;
`

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${Media({
    marginBottom: ['40px', null, null, '0']
  })}
`

export const StyledMediaInner = styled.div`
  left: 0;

  ${Media({
    position: ['relative', null, null, 'absolute'],
    height: ['auto', null, null, '100%'],
    width: ['calc(100vw - 1.5rem)', null, `calc(100vw - ${getColumnWidth(1)}vw - 1.5rem)`, 'calc(50vw - 0.75rem)'],
    overflow: ['visible', null, 'hidden']
  })}

  .gatsby-image-wrapper {
    ${Media({
      height: ['auto', null, null, '100%']
    })}
  }
`

export const StyledButton = styled(Button)`
  width: 100%;

  ${Media({
    maxWidth: ['none', null, null, '495px']
  })}
`
