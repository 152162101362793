import React from 'react'
import { useIntl, IntlContext } from 'react-intl'
//
import { buildSlug, buildNavigationItem, NavLink } from '~utils/link'
import { getSourceImage, ImageFluid } from '~utils/image'
import {
  StyledContainer,
  StyledItemTextContainer,
  StyledCategoryTitle,
  StyledItemTitle,
  StyledItemDescription,
  StyledItem,
  StyledItemLinkInner,
  StyledLinkWrapper
} from './Styled'
import { getEntryPrefix } from '~utils/common'
import RichText from '../rich-text'

type withCardProps = {
  backgroundColor: string
  ctaTextId?: string
  prefix?: string
}

interface CardProps extends withCardProps {
  item: any
}

type getCtaProps = {
  item: any
  ctaTextId?: string
}

const getCta = ({ item, ctaTextId }: getCtaProps) => {
  const intl = useIntl()

  if (ctaTextId || item?.cta) {
    const to = ctaTextId ? buildSlug(item) : buildNavigationItem(item?.cta)?.to
    const title = ctaTextId ? intl.formatMessage({ id: ctaTextId }) : item?.cta?.title

    return {
      to,
      title,
      externalUrl: !!item?.cta?.externalUrl
    }
  }

  return null
}

type LinkWrapperProps = {
  children: any
  to: string | null | undefined
  ariaLabel?: string
}

const LinkWrapper = ({ children, to = null, ariaLabel }: LinkWrapperProps) => {
  return to ? (
    <StyledLinkWrapper>
      <NavLink to={to} ariaLabel={ariaLabel}>
        {children}
      </NavLink>
    </StyledLinkWrapper>
  ) : (
    <>{children}</>
  )
}

const Card = ({ item, prefix, ctaTextId, backgroundColor }: CardProps) => {
  const parsedImage = getSourceImage(item?.image)
  const prefixValue = prefix || item?.categories?.[0]?.title
  const intl = React.useContext(IntlContext)
  const cta = getCta({ item, ctaTextId })
  const ctaProps = {
    to: cta?.to,
    ...(item?.cta?.externalUrl && {
      target: '_blank',
      rel: 'noreferrer nofollow'
    })
  }

  const ariaLabel = intl.formatMessage({ id: 'a11y.cta' }, { title: item?.title })

  return (
    <StyledContainer backgroundColor={backgroundColor}>
      <LinkWrapper to={cta?.to} ariaLabel={ariaLabel}>
        {parsedImage && <ImageFluid image={parsedImage} alt={item?.title || ''} />}
        <StyledItemTextContainer>
          {item?.__typename === 'ContentfulEvent' && (
            <StyledCategoryTitle>{[item?.city, item?.niceDate].filter(i => i).join(` • `)}</StyledCategoryTitle>
          )}

          {prefixValue && <StyledCategoryTitle>{prefixValue}</StyledCategoryTitle>}
          <StyledItemTitle>{item?.title}</StyledItemTitle>

          {item?.description && <RichText data={item?.description?.json} />}

          {item?.shortDescription?.shortDescription && (
            <StyledItemDescription>{item?.shortDescription?.shortDescription}</StyledItemDescription>
          )}
          {cta && (
            <StyledItem {...ctaProps}>
              <StyledItemLinkInner>{cta?.title}</StyledItemLinkInner>
            </StyledItem>
          )}
        </StyledItemTextContainer>
      </LinkWrapper>
    </StyledContainer>
  )
}

export const withCard = ({ backgroundColor, ctaTextId }: withCardProps) => (props: any) => (
  <Card
    prefix={getEntryPrefix(props?.item?.__typename)}
    {...props}
    backgroundColor={backgroundColor}
    ctaTextId={ctaTextId}
  />
)

export default Card
