import React from 'react'
import { Flex } from 'rebass'
//
import { ContentfulStackedBlocks, ContentfulStackedBlock } from '~generated/gatsby.types'
import theme from '~theme'
import EmbeddedVideo from '~components/base-components/embedded-video'
import { getSourceImage, ImageFluid } from '~utils/image'
import { buildNavigationItem, NavLink } from '~utils/link'
import RoughEdge from '../../base-components/rough-edge'
import { Grid, Cell } from '../../base-components/grid'
import CTA from '../../base-components/cta'
import {
  StyledTopSection,
  StyledTitle,
  StyledDescription,
  StyledTextContainer,
  StyledBlocks,
  StyledCategoryTitle,
  StyledBlockTitle,
  StyledBlockDescription,
  StyledMediaContainer,
  StyledBlockTextContainer,
  StyledBlockGrid
} from './Styled'

const StackedBlocks = ({ id, title, shortDescription, cta, blocks }: ContentfulStackedBlocks) => (
  <>
    <RoughEdge id={`top-${id}`} backgroundColor={theme.colors.mustard} />
    <StyledTopSection>
      <Grid>
        <Cell cols={[12, null, 8, 6, 4]} offset={[null, null, 2, 3, 4]}>
          <StyledTextContainer>
            <Flex flexDirection="column" alignItems="center">
              <StyledTitle>{title}</StyledTitle>
              <StyledDescription>{shortDescription?.shortDescription}</StyledDescription>
              {cta && <CTA {...cta} theme="black" fullWidth />}
            </Flex>
          </StyledTextContainer>
        </Cell>
      </Grid>
    </StyledTopSection>
    <RoughEdge id={`bottom-${id}`} backgroundColor={theme.colors.white} />
    <StyledBlocks overlap>
      {blocks?.map((block: ContentfulStackedBlock | null | undefined) => {
        if (!block) {
          return null
        }

        const parsedImage = getSourceImage(block.image)
        const navItem = block?.cta && buildNavigationItem(block.cta)

        return (
          <StyledBlockGrid key={block.id}>
            <Cell cols={[12, null, 10, 8, 6]} offset={[null, null, 1, 2]}>
              <StyledMediaContainer>
                {block.videoUrl ? (
                  <EmbeddedVideo id={`block-${block.id}`} title={block.title} videoUrl={block.videoUrl} />
                ) : (
                  parsedImage && <ImageFluid image={parsedImage} alt={block.title || ''} />
                )}
              </StyledMediaContainer>
            </Cell>
            <Cell cols={[12, null, 10, 6, 3]} offset={[null, null, 1, 3, 0]}>
              <StyledBlockTextContainer>
                <StyledCategoryTitle>{block?.category?.title}</StyledCategoryTitle>
                <StyledBlockTitle>{block?.title}</StyledBlockTitle>
                <StyledBlockDescription>{block?.shortDescription?.shortDescription}</StyledBlockDescription>
                {navItem && navItem.to ? <NavLink to={navItem.to}>{navItem.title}</NavLink> : null}
              </StyledBlockTextContainer>
            </Cell>
          </StyledBlockGrid>
        )
      })}
    </StyledBlocks>
  </>
)

export default StackedBlocks
