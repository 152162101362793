import React, { useState } from 'react'
import { IntlContext } from 'react-intl'
//
import { useCurrentBreakpoint } from '~utils/hooks'
import { mediumBreakpoint } from '~utils/breakpoints'
import Share from './icons/Share'
import {
  StyledContainer,
  StyledShareButton,
  StyledLinksContainer,
  StyledLinksList,
  StyledLinksListItem,
  StyledLink
} from './Styled'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import WhatsApp from './icons/WhatsApp'

const isBrowser = typeof window !== 'undefined'

const Social = ({ position = 'horizontal' }) => {
  const intl = React.useContext(IntlContext)
  const currentBreakpoint = useCurrentBreakpoint()
  const [transitionState, setTransitionState] = useState('closed')

  const hasTouch = isBrowser && 'ontouchstart' in window

  const isMobile = currentBreakpoint < mediumBreakpoint

  const url = isBrowser ? window.location.href : ''
  const title = isBrowser ? document.title : ''
  const text = `${title.replace('|', '-')} ${url}`

  const containerProps = {
    ...(hasTouch && {
      onClick: () => {
        setTransitionState(transitionState === 'closed' ? 'opening' : 'closing')
      }
    }),
    ...(!hasTouch && {
      onMouseEnter: () => setTransitionState('opening'),
      onMouseLeave: () => setTransitionState('closing')
    })
  }

  return (
    <StyledContainer
      {...containerProps}
      onTransitionEnd={e => {
        if (e.propertyName === 'transform') {
          if (transitionState === 'closing') {
            setTransitionState('closed')
          } else {
            setTransitionState('open')
          }
        }
      }}
    >
      <StyledShareButton transitionState={transitionState} aria-label={intl.formatMessage({ id: 'a11y.share.icon' })}>
        <Share />
      </StyledShareButton>
      {isBrowser ? (
        <StyledLinksContainer isMobile={isMobile} position={position} transitionState={transitionState}>
          <StyledLinksList isMobile={isMobile} position={position} transitionState={transitionState}>
            <StyledLinksListItem>
              <StyledLink
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                target="_blank"
                rel="noreferrer nofollow"
                className="facebook-share"
                aria-label={intl.formatMessage({ id: 'a11y.share.network' }, { network: 'Facebook' })}
              >
                <Facebook />
              </StyledLink>
            </StyledLinksListItem>
            <StyledLinksListItem>
              <StyledLink
                href={`https://twitter.com/intent/tweet?text=${text}`}
                target="_blank"
                rel="noreferrer nofollow"
                className="twitter-share"
                aria-label={intl.formatMessage({ id: 'a11y.share.network' }, { network: 'Twitter' })}
              >
                <Twitter />
              </StyledLink>
            </StyledLinksListItem>
            <StyledLinksListItem>
              <StyledLink
                href={`https://api.whatsapp.com/send?text=${text}`}
                target="_blank"
                rel="noreferrer nofollow"
                className="whatsapp-share"
                aria-label={intl.formatMessage({ id: 'a11y.share.network' }, { network: 'Whatsapp' })}
              >
                <WhatsApp />
              </StyledLink>
            </StyledLinksListItem>
          </StyledLinksList>
        </StyledLinksContainer>
      ) : null}
    </StyledContainer>
  )
}

export default Social
