import React from 'react'

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="30" viewBox="0 0 48 30">
    <g fill="none" fillRule="evenodd" stroke="#EDE9E6">
      <path
        strokeLinecap="square"
        d="M46.722 14.724L0.822 14.824"
        transform="translate(0 .452) rotate(-180 23.972 14.574)"
      />
      <path d="M0 28.548L14.922 14.274 0.708 0" transform="translate(0 .452) rotate(-180 7.461 14.274)" />
    </g>
  </svg>
)

export default Arrow
