import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ContentfulBaristaColors, ContentfulEvent } from '~generated/gatsby.types'

import theme from '~theme'
import { baristaReadableButtonTheme } from '~utils/colors'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import CTA from '../../base-components/cta'
import Carousel, { ArrowNavigationButtons } from '../../carousel'
import { StyledContainer, StyledTitle, StyledDescription } from '../content-carousel/Styled'
import { withCard } from '../../base-components/card'

type Props = {
  id: string
  title?: string | null | undefined
  backgroundColor?: ContentfulBaristaColors | null | undefined
  bgColorOverride?: string | null | undefined
  shortDescription?: any | undefined
  cta?: any | null | undefined
  eventType: string
}

const EventCarousel = ({ id, backgroundColor, bgColorOverride, title, shortDescription, cta, eventType }: Props) => {
  const [carouselState, setCarouselState] = useState({})
  const bgColor = bgColorOverride || backgroundColor?.colorCode || theme.colors.oatmealBrown
  const description = shortDescription && shortDescription[Object.keys(shortDescription)[0]]
  const Card = withCard({ ctaTextId: 'contentCarousel.cta', backgroundColor: bgColor })

  const eventsData = useStaticQuery(graphql`
    query {
      allContentfulEvent {
        nodes {
          __typename
          id
          title
          slug
          description {
            json
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
          city
          niceDate: fromDate(formatString: "DD MMM")
          fromDate
        }
      }
    }
  `)

  if (!eventsData) return null

  const eventNodes = eventsData.allContentfulEvent.nodes
  const [eventItems, setEventItems] = useState([])

  useEffect(() => {
    if (eventType === 'Upcoming events') {
      setEventItems(eventNodes.filter((item: { fromDate: string }) => Date.parse(item.fromDate) >= Date.now()))
    } else if (eventType === 'Past events') {
      setEventItems(eventNodes.filter((item: { fromDate: string }) => Date.parse(item.fromDate) < Date.now()))
    } else {
      setEventItems(eventNodes)
    }
  }, []) // events filtering only needs to run once

  // Only show carousel if there are items
  return eventItems.length > 0 ? (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer backgroundColor={bgColor}>
        <Grid>
          <Cell cols={[12, null, 9, 6, 5]} offset={[null, null, 1]}>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
          </Cell>
        </Grid>

        <Grid>
          <Cell cols={[12, null, 8]} offset={[null, null, 1]}>
            {cta && <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} fullWidth />}
          </Cell>
          <Cell cols={[0, null, 2]}>
            <ArrowNavigationButtons id={id} carouselState={carouselState} backgroundColor={bgColor} />
          </Cell>
        </Grid>
        {eventItems.length ? (
          <Carousel id={id} contents={eventItems} backgroundColor={bgColor} Card={Card} onChange={setCarouselState} />
        ) : null}
      </StyledContainer>
    </>
  ) : null
}

export default EventCarousel
