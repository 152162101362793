import styled from 'styled-components'
//
import { Media } from '~utils'
import { baristaReadableColor } from '~utils/colors'
import { Grid, maxWidth, noOfColumns } from '../base-components/grid'

export const StyledContainer = styled.div`
  overflow: hidden;
`

interface StyledNavigationButtonProps {
  direction: string
  backgroundColor: string
}

export const StyledNavigationButton = styled.button<StyledNavigationButtonProps>`
  background: none;
  border: none;
  position: absolute;
  transform: translateY(-50%);
  left: ${props => (props.direction === 'left' ? '4%' : 'auto')};
  right: ${props => (props.direction === 'right' ? '4%' : 'auto')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin-top: 60px;

  * {
    stroke: ${props => (props.disabled ? props.theme.colors.lightGrey : baristaReadableColor(props.backgroundColor))};
  }
`

export const StyledCarouselGrid = styled(Grid)`
  position: relative;

  @media (min-width: ${maxWidth}px) {
    max-width: calc(100vw - (50vw - ${maxWidth / 2}px + ${maxWidth / noOfColumns}px));
    margin-left: calc(50vw - ${maxWidth / 2}px + ${maxWidth / noOfColumns}px);

    > * {
      margin-left: 0;
      width: 100%;
    }
  }

  > * {
    ${Media({
      paddingRight: [null, null, '0']
    })}
  }

  ${Media({
    paddingRight: [null, null, '0']
  })}

  .swiper-container {
    width: 100%;
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }
`

interface StyledCarouselItemProps {
  widths: Array<string | null>
}

export const StyledCarouselItem = styled.div<StyledCarouselItemProps>`
  display: flex;
  flex-direction: column;

  ${props =>
    Media({
      width: props.widths
    })}

  &:last-child {
    ${props =>
      Media({
        paddingRight: [null, null, '104px'],
        width: props.widths.map(width =>
          width === 'auto' || width === null || width === '100%' ? width : `calc(${width} + 104px)`
        )
      })}
  }
`

interface StyledArrowNavigationButtonsContainerProps {
  justify: string
}

export const StyledArrowNavigationButtonsContainer = styled.div<StyledArrowNavigationButtonsContainerProps>`
  display: flex;
  justify-content: ${props => props.justify};
`

interface StyledArrowNavigationButtonProps {
  direction: string
  backgroundColor: string
}

export const StyledArrowNavigationButton = styled.button<StyledArrowNavigationButtonProps>`
  background: none;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  transform: rotate(${props => (props.direction === 'right' ? '180deg' : '0deg')});
  position: relative;
  top: ${props => (props.direction === 'right' ? '-3px' : '0')};
  margin: 0 24px 0 0;
  padding: 0;
  outline: 0;

  * {
    stroke: ${props => (props.disabled ? props.theme.colors.lightGrey : baristaReadableColor(props.backgroundColor))};
  }
`
