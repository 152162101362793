import React from 'react'

type Props = {
  direction?: string
}

const Chevron = ({ direction = 'left' }: Props) => {
  const transform = direction === 'left' ? 'rotate(-180 4 8)' : ''
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" viewBox="0 0 8 17">
      <path fill="none" fillRule="evenodd" stroke="#4A4A4A" d="M0 15L8 7.5 0.38 0" transform={transform} />
    </svg>
  )
}

export default Chevron
