import styled from 'styled-components'
//
import { Media } from '~utils'
import { Grid } from '../../base-components/grid'

export const maxWidth = 1480

export const StyledTopSection = styled.div`
  background-color: ${props => props.theme.colors.mustard};

  ${Media({
    padding: ['28px 0 140px', null, '70px 0 140px', null, '110px 0 80px']
  })}
`

export const StyledTextContainer = styled.div`
  ${Media({
    marginBottom: ['40px', null, '48px', '88px']
  })}
`

export const StyledTitle = styled.h2`
  font-weight: normal;
  text-align: center;
  color: ${props => props.theme.colors.black};

  ${Media({
    fontSize: ['32px', null, '40px'],
    letterSpacing: ['0.8px', null, '0.67px', '1px'],
    margin: ['0 0 24px', null, '0 0 16px']
  })}
`

export const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 24px;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.black};
`

interface StyledBlocksProps {
  readonly overlap?: boolean
}

export const StyledBlocks = styled.div<StyledBlocksProps>`
  padding: 0 0 20px;
  position: relative;
  z-index: 2;

  ${props =>
    props.overlap
      ? Media({
          marginTop: ['-155px', null, null, null, '-55px']
        })
      : Media({
          marginTop: ['2.5rem', null, null, null, '4rem']
        })}
`

export const StyledBlocksNoMargin = styled.div`
  padding: 0 0 20px;
  position: relative;
  z-index: 2;
`

export const StyledMediaContainer = styled.div`
  ${Media({
    marginBottom: ['40px', null, '48px', null, '0']
  })}
`

interface StyledBlockTextContainerProps {
  readonly leftAlignItems?: boolean
  readonly noCta?: boolean
}

export const StyledBlockTextContainer = styled.div<StyledBlockTextContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${Media({
    marginLeft: [null, null, null, null, '80px']
  })}

  ${props => props.noCta && `padding-bottom: 48px;`}

  ${props =>
    props.leftAlignItems
      ? Media({
          alignItems: ['flex-start', null, null, null, null]
        })
      : Media({
          alignItems: ['flex-start', null, null, 'center', 'flex-start']
        })}

  > a {
    display: inline-block;
    color: ${props => props.theme.colors.black};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1.83;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.colors.black};
    padding-bottom: 2px;

    ${Media({
      marginBottom: ['40px', null, '48px', null, '0']
    })}

    &:hover {
      color: ${props => props.theme.colors.darkGrey};
      border-color: ${props => props.theme.colors.darkGrey};
    }
  }
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.black};

  ${Media({
    margin: ['0 0 24px', null, '0 0 16px', '0 0 16px'],
    textAlign: ['left', null, null, 'center', 'left']
  })}
`

export const StyledBlockTitle = styled.h3`
  font-weight: normal;
  color: ${props => props.theme.colors.primary};

  ${Media({
    fontSize: ['24px', null, '28px'],
    lineHeight: ['1.5', null, '1.29'],
    letterSpacing: ['0.86px', null, '1px'],
    margin: ['0 0 24px', null, '0 0 16px'],
    textAlign: ['left', null, null, 'center', 'left']
  })}
`

export const StyledBlockDescription = styled.p`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.black};
  white-space: pre-wrap;

  ${Media({
    margin: ['0 0 24px', null, null, '0 0 16px'],
    textAlign: ['left', null, null, 'center', 'left']
  })}
`

interface StyledBlockGridProps {
  readonly rowReverse?: boolean
}

export const StyledBlockGrid = styled(Grid)<StyledBlockGridProps>`
  overflow-x: hidden;
  max-width: ${maxWidth}px;

  ${props =>
    props.rowReverse &&
    Media({
      flexDirection: [null, null, null, 'row-reverse', null]
    })}

  ${Media({
    marginBottom: [null, null, null, null, '104px']
  })}
`
