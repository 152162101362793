import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'react-intl'
//
import {
  ContentfulListingBlock,
  ContentfulBaristaEdge,
  ContentfulTechniqueEdge,
  ContentfulRecipeEdge,
  ContentfulArticle,
  ContentfulBarista,
  ContentfulTechnique,
  ContentfulRecipe,
  ContentfulEventEdge,
  ContentfulEvent,
  ContentfulCategory
} from '~generated/gatsby.types'
import theme from '~theme'
import RoughEdge from '../../base-components/rough-edge'
import { Grid, Cell } from '../../base-components/grid'
import { StyledContainer, StyledTitle, StyledFilterWrapper, StyledFilterButton } from './Styled'
import {
  StyledBlocks,
  StyledCategoryTitle,
  StyledBlockTitle,
  StyledMediaContainer,
  StyledBlockTextContainer,
  StyledBlockGrid
} from '../stacked-blocks/Styled'
import CardList from '../../card-list'
import { withCard } from '../../base-components/card'
import { useCurrentBreakpoint } from '~utils/hooks'
import { largeBreakpoint } from '~utils/breakpoints'
import BaristaCard from './BaristaCard'
import { getEntryPrefix } from '~utils/common'
import { ImageFluid, getSourceImage } from '~utils/image'
import RichText from '~components/base-components/rich-text'
import { NavLink } from '~utils/link'
import { StyledFeaturedItemDescription } from '~components/base-components/card/Styled'

interface CustomEvent extends ContentfulEvent {
  niceDate?: string
  niceTime?: string
}

const ContentCard = withCard({ ctaTextId: 'listingBlock.cta', backgroundColor: theme.colors.white })

const Card = ({ item, ...rest }) => {
  if (!item) {
    return null
  }

  return item?.__typename === 'ContentfulBarista' ? (
    <BaristaCard prefix={getEntryPrefix(item?.__typename)} item={item} {...rest} />
  ) : (
    <ContentCard prefix={getEntryPrefix(item?.__typename)} item={item} {...rest} />
  )
}

const ListingBlock = ({ id, title, contentTypes, featuredContent }: ContentfulListingBlock) => {
  const items: any = []
  const intl = useIntl()

  const baristasData = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            __typename
            id
            title
            slug
            categories {
              title
            }
            shortDescription {
              shortDescription
            }
            image {
              bynder: bynderAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
              local: localAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
            }
          }
        }
      }
      allContentfulBarista {
        edges {
          node {
            __typename
            id
            title
            slug
            shortDescription {
              shortDescription
            }
            signature
            location
            publishedDate
            categories {
              title
            }
            image {
              bynder: bynderAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80, resizingBehavior: FILL, cropFocus: FACE) {
                  ...QueryImageFluid
                }
              }
              local: localAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80, resizingBehavior: FILL, cropFocus: FACE) {
                  ...QueryImageFluid
                }
              }
            }
          }
        }
      }
      allContentfulTechnique {
        edges {
          node {
            __typename
            id
            title
            slug
            shortDescription {
              shortDescription
            }
            publishedDate
            categories {
              title
            }
            image {
              bynder: bynderAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
              local: localAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
            }
          }
        }
      }
      allContentfulRecipe {
        edges {
          node {
            __typename
            id
            title
            slug
            shortDescription {
              shortDescription
            }
            publishedDate
            categories {
              title
            }
            image {
              bynder: bynderAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
              local: localAsset {
                fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                  ...QueryImageFluid
                }
              }
            }
          }
        }
      }
      allContentfulEvent(sort: { fields: fromDate, order: ASC }) {
        edges {
          node {
            __typename
            id
            title
            slug
            fromDate
            publishedDate: fromDate
            displayDate
            niceTime: fromDate(formatString: "hA")
            niceDate: fromDate(formatString: "DD MMM")
            addressLine1
            addressLine2
            city
            postcode
            description {
              json
            }
            cta {
              title
              externalUrl
              accessibleText
              content {
                ...FragmentNavigationElement
              }
            }
            image {
              bynder: bynderAsset {
                fluid(maxWidth: 820, maxHeight: 546, quality: 80) {
                  ...QueryImageFluid
                }
              }
              local: localAsset {
                fluid(maxWidth: 820, maxHeight: 546, quality: 80) {
                  ...QueryImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allContentfulArticle,
    allContentfulBarista,
    allContentfulTechnique,
    allContentfulRecipe,
    allContentfulEvent
  } = baristasData

  const [selectedCategory, setSelectedCategory] = useState('All')
  const categories: any = {}

  if (contentTypes.includes('Article')) {
    allContentfulArticle.edges
      .map((edge: ContentfulBaristaEdge) => edge.node)
      .forEach((node: ContentfulArticle) => {
        items.push(node)
        node?.categories?.forEach((cat: ContentfulCategory) => {
          if (!cat?.title) {
            return
          }
          if (!categories[cat.title]) {
            categories[cat.title] = 1
          } else {
            categories[cat.title] += 1
          }
        })
      })
  }

  if (contentTypes.includes('Barista')) {
    allContentfulBarista.edges
      .map((edge: ContentfulBaristaEdge) => edge.node)
      .forEach((node: ContentfulBarista) => {
        items.push(node)
      })
  }

  if (contentTypes.includes('Technique')) {
    allContentfulTechnique.edges
      .map((edge: ContentfulTechniqueEdge) => edge.node)
      .forEach((node: ContentfulTechnique) => {
        items.push(node)
      })
  }

  if (contentTypes.includes('Recipe')) {
    allContentfulRecipe.edges
      .map((edge: ContentfulRecipeEdge) => edge.node)
      .forEach((node: ContentfulRecipe) => {
        items.push(node)
      })
  }

  if (contentTypes.includes('Event')) {
    allContentfulEvent.edges
      .map((edge: ContentfulEventEdge) => edge.node)
      .forEach((node: ContentfulEvent) => {
        items.push(node)
      })
  }

  const intersectionObserverRef = useRef(null)
  const [endSliceIndex, setEndSliceIndex] = useState(9)
  const currentBreakpoint = useCurrentBreakpoint()

  const isEvents = contentTypes.includes('Event')
  const isNews = contentTypes.includes('Article')
  const [eventItems, setEventItems] = useState([])

  useEffect(() => {
    if (isEvents) setEventItems(items.filter((item: { fromDate: string }) => Date.parse(item.fromDate) >= Date.now()))
  }, []) // events filtering only needs to run once

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const observerRefCurr = intersectionObserverRef?.current

    if (observerRefCurr) {
      const observer: any = new IntersectionObserver(
        () => {
          setEndSliceIndex(items.length)
          observer.unobserve(observerRefCurr)
        },
        {
          rootMargin: '500px',
          threshold: 1.0
        }
      )

      observer.observe(observerRefCurr)

      return () => {
        observer.unobserve(observerRefCurr)
      }
    }
  }, [items, intersectionObserverRef])

  useEffect(() => {
    if (currentBreakpoint < largeBreakpoint) {
      setEndSliceIndex(items.length)
    }
  }, [currentBreakpoint])

  const filteredItems =
    isNews && selectedCategory !== 'All'
      ? [...items].filter(item => item?.categories?.some(cat => cat.title === selectedCategory))
      : [...items]
          .sort((a: any, b: any) => {
            const aDate = new Date(a?.publishedDate)
            const bDate = new Date(b?.publishedDate)

            return bDate.getTime() - aDate.getTime()
          })
          .slice(0, endSliceIndex)

  const featuredContentToShow = () => {
    if (!featuredContent || selectedCategory !== 'All') {
      return null
    }

    if (
      isNews &&
      selectedCategory !== 'All' &&
      !featuredContent?.categories.some(cat => cat?.title === selectedCategory)
    ) {
      return null
    }

    return (
      <Grid key={featuredContent?.id} className="featured-wrapper">
        <Cell cols={[12, null, 10, 6.33, null]} offset={[null, null, 1]}>
          <StyledMediaContainer>
            {getSourceImage(featuredContent?.image) && (
              <ImageFluid image={getSourceImage(featuredContent?.image)} alt={featuredContent?.title || ''} />
            )}
          </StyledMediaContainer>
        </Cell>
        <Cell cols={[12, null, 10, 2.67, null]} offset={[null, null, 1]}>
          <StyledBlockTextContainer leftAlignItems className="featured">
            {featuredContent?.categories && (
              <StyledCategoryTitle>{featuredContent?.categories?.map(i => i.title).join(` • `)}</StyledCategoryTitle>
            )}
            <StyledBlockTitle>{featuredContent?.title}</StyledBlockTitle>
            {featuredContent?.shortDescription?.shortDescription && (
              <StyledFeaturedItemDescription>
                {featuredContent?.shortDescription?.shortDescription}
              </StyledFeaturedItemDescription>
            )}
            {!featuredContent?.shortDescription?.shortDescription && featuredContent?.description?.json && (
              <RichText data={featuredContent?.description?.json} />
            )}
            <NavLink to={featuredContent?.slug}>{intl.formatMessage({ id: 'news.featured.readmore' })}</NavLink>
          </StyledBlockTextContainer>
        </Cell>
      </Grid>
    )
  }

  return (
    <>
      <RoughEdge id={id} backgroundColor={theme.colors.white} />

      <StyledContainer>
        {isNews && Object.keys(categories).length > 0 && (
          <Grid>
            <Cell offset={[null, null, 1]}>
              <StyledFilterWrapper>
                <span>
                  {intl.formatMessage({
                    id: 'news.filter.label'
                  })}
                </span>
                <StyledFilterButton
                  className={selectedCategory === 'All' ? 'selected' : ''}
                  onClick={() => setSelectedCategory('All')}
                >
                  {intl.formatMessage({ id: 'news.filter.all' })} ({items.length})
                </StyledFilterButton>
                {Object.keys(categories).map(key => {
                  return (
                    <StyledFilterButton
                      className={selectedCategory === key ? 'selected' : ''}
                      onClick={() => setSelectedCategory(key)}
                    >{`${key} (${categories[key]})`}</StyledFilterButton>
                  )
                })}
              </StyledFilterWrapper>
            </Cell>
          </Grid>
        )}

        {featuredContentToShow()}

        <Grid>
          <Cell offset={!isEvents && [null, null, 1]}>
            <StyledTitle centered={isEvents}>
              {title?.replace('{total}', isEvents ? eventItems!.length : isNews ? filteredItems.length : items.length)}
            </StyledTitle>
          </Cell>
        </Grid>

        {isEvents ? (
          <StyledBlocks>
            {eventItems.map((item: CustomEvent) => {
              const parsedImage = getSourceImage(item.image)

              return (
                <StyledBlockGrid key={item.id} rowReverse>
                  <Cell cols={[13, null, 12, 7, null]} offset={[null, null, 0.65, 1, null]}>
                    <StyledMediaContainer>
                      {parsedImage && <ImageFluid image={parsedImage} alt={item.title || ''} />}
                    </StyledMediaContainer>
                  </Cell>
                  <Cell cols={[12, null, 10, 4, null]} offset={[null, null, 0.65, 0, null]}>
                    <StyledBlockTextContainer leftAlignItems>
                      {item?.__typename === 'ContentfulEvent' ? (
                        <StyledCategoryTitle>
                          {[item?.niceDate, item?.city, item?.niceTime].filter(i => i).join(` • `)}
                        </StyledCategoryTitle>
                      ) : (
                        <StyledCategoryTitle>{item.displayDate}</StyledCategoryTitle>
                      )}
                      <StyledBlockTitle>{item.title}</StyledBlockTitle>
                      <RichText data={item.description?.json} />
                      <NavLink to={`/events/${item.slug}/`}>{intl.formatMessage({ id: 'event.readmore' })}</NavLink>
                    </StyledBlockTextContainer>
                  </Cell>
                </StyledBlockGrid>
              )
            })}
          </StyledBlocks>
        ) : (
          <CardList
            key={`carousel-${selectedCategory.replace(/\s+/g, '-')}`}
            id={id}
            backgroundColor={theme.colors.white}
            contents={filteredItems}
            Card={Card}
          />
        )}

        <div ref={intersectionObserverRef} />
      </StyledContainer>
    </>
  )
}

export default ListingBlock
