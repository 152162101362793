import React from 'react'
//
import { ContentfulImage } from '~generated/gatsby.types'
import { StyledMedia, StyledMediaInner } from './Styled'
import { getSourceImage, ImageFluid } from '~utils/image'

type Props = {
  image?: ContentfulImage | null | undefined
}

const TertiaryHero = ({ image }: Props) => {
  const parsedImage = getSourceImage(image)

  return (
    <StyledMedia>
      <StyledMediaInner>{parsedImage && <ImageFluid image={parsedImage} />}</StyledMediaInner>
    </StyledMedia>
  )
}

export default TertiaryHero
