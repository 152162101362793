import React, { useRef } from 'react'
import fetchPonyfill from 'fetch-ponyfill'
import { FormattedMessage } from 'react-intl'
//
import { ContentfulVotingBlock, ContentfulSettings } from '~generated/gatsby.types'
import { queryParams } from '~utils'
import { IntlContext } from '~i18n'
import theme from '~theme'
import {
  StyledContainer,
  StyledContent,
  StyledCategoryTitle,
  StyledTitle,
  StyledShortDescription,
  StyledLabel,
  StyledInput,
  StyledMedia,
  StyledMediaInner,
  StyledButton
} from './Styled'
import { getSourceImage, ImageFluid } from '~utils/image'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import { baristaReadableButtonTheme } from '~utils/colors'
import { LocationContext } from '~layout/wrapper'

const environment = process.env.CONTENTFUL_ENVIRONMENT_ID
const NETLIFY_FORM_NAME = `voting-${environment}`

const { fetch, Headers } = fetchPonyfill()
const headers = new Headers({
  'Content-Type': 'application/x-www-form-urlencoded'
})

interface Props extends ContentfulVotingBlock {
  contentfulSettings: ContentfulSettings
  bgColorOverride: string | null | undefined
}

const VotingBlock = ({
  id,
  contentful_id: contentfulId,
  title,
  slug,
  shortDescription,
  questions,
  backgroundColor,
  image,
  category,
  contentfulSettings,
  bgColorOverride
}: Props) => {
  if (!questions || !questions.length) {
    return null
  }

  const votingRef: any = useRef(null)
  const location: any = React.useContext(LocationContext)
  const intl: any = React.useContext(IntlContext)

  const votingSlug = `${location?.pathname}${slug}`

  const [selected, setSelected] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const [successfulSubmission, setSuccessfulSubmission] = React.useState(false)

  const handleQuestionChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    setSelected(target.value)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (submitting) {
      return
    }

    setSubmitting(true)

    const answer = questions.findIndex((question: any) => question.id === selected)

    const values = {
      title,
      poll_id: contentfulId,
      locale: intl.id,
      answer: answer + 1,
      poll_slug: votingSlug
    }

    const options: RequestInit = {
      method: 'POST',
      headers,
      credentials: 'include'
    }

    options.body = queryParams({ 'form-name': NETLIFY_FORM_NAME, ...values })

    const response = await fetch('/', options)

    if (response.ok) {
      setSuccessfulSubmission(true)
      window.scroll({
        top: votingRef.current.offsetTop - 120,
        left: 0,
        behavior: 'smooth'
      })
    }

    setSubmitting(false)
    setSelected('')
  }

  const bgColor = bgColorOverride || backgroundColor?.colorCode || theme.colors.oatmealBrown
  const readableBgColor = baristaReadableButtonTheme(bgColor)
  const selectedBgColor = readableBgColor === 'white' ? theme.colors.white : theme.colors.latteBrown
  const selectedFgColor = readableBgColor === 'white' ? theme.colors.black : theme.colors.white
  const parsedImage = getSourceImage(image)

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer ref={votingRef} key={`component-${id}`} backgroundColor={bgColor} className="voting-section">
        <Grid>
          <Cell offset={[0, null, 1]} cols={[12, null, 10, 4]} order={[2, null, null, 1]}>
            <StyledContent backgroundColor={bgColor}>
              <StyledCategoryTitle>{category?.title}</StyledCategoryTitle>
              {successfulSubmission ? (
                <>
                  <StyledTitle tabIndex={-1} ref={el => el?.focus()}>
                    {contentfulSettings?.votingThankYouMessage?.votingThankYouMessage}
                  </StyledTitle>
                </>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  name={NETLIFY_FORM_NAME}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
                  <input type="hidden" name="title" value="" />
                  <input type="hidden" name="poll_id" value="" />
                  <input type="hidden" name="answer" value="" />
                  <input type="hidden" name="poll_slug" value={votingSlug} />
                  <input type="hidden" name="locale" value={intl.id} />

                  <StyledTitle>{title}</StyledTitle>
                  <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>

                  {questions.map(question => {
                    if (!question) {
                      return null
                    }
                    return (
                      <StyledLabel
                        htmlFor={`question-${question.id}`}
                        key={question.id}
                        isSelected={question.id === selected}
                        selectedBgColor={selectedBgColor}
                        selectedFgColor={selectedFgColor}
                      >
                        <StyledInput
                          id={`question-${question.id}`}
                          type="radio"
                          value={question.id}
                          checked={question.id === selected}
                          onChange={handleQuestionChange}
                        />
                        <span>{question.question}</span>
                      </StyledLabel>
                    )
                  })}
                  <StyledButton theme="black" type="submit" disabled={selected === '' && !submitting} fullWidth>
                    <FormattedMessage id="voting.button.submit" />
                  </StyledButton>
                </form>
              )}
            </StyledContent>
          </Cell>
          <Cell offset={[0, null, 1]} cols={[12, null, 10, 6]} order={[1, null, null, 2]}>
            <StyledMedia>
              <StyledMediaInner>{parsedImage && <ImageFluid image={parsedImage} alt={title || ''} />}</StyledMediaInner>
            </StyledMedia>
          </Cell>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default VotingBlock
