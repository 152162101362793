import styled from 'styled-components'
//

export const StyledContainer = styled.div`
  position: relative;
  width: max-content;
`

interface StyledShareButtonProps {
  transitionState: string
}

export const StyledShareButton = styled.button<StyledShareButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 20px;
  background: ${props => (props.transitionState === 'closed' ? 'none' : props.theme.colors.oatmealBrown)};
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 1;
  position: relative;
`

interface StyledLinksContainerProps {
  transitionState: string
  position: string
  isMobile: boolean
}

export const StyledLinksContainer = styled.div<StyledLinksContainerProps>`
  position: absolute;
  overflow: hidden;

  ${props =>
    props.position === 'vertical' && props.isMobile
      ? `
    width: 100%;
    height: ${props.transitionState === 'closed' ? '0' : '156px'};
    bottom: 20px;
  `
      : `
    width: ${props.transitionState === 'closed' ? '0' : '156px'};
    height: 100%;
    top: 0;
    left: 20px;
  `}
`

export const StyledLinksListItem = styled.li`
  padding: 0;
`

interface StyledLinksListProps {
  transitionState: string
  position: string
  isMobile: boolean
}

export const StyledLinksList = styled.ul<StyledLinksListProps>`
  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.black};
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.oatmealBrown};

  ${props =>
    props.transitionState === 'opening' || props.transitionState === 'closing' ? 'transition: transform 250ms;' : ''}

  ${props =>
    props.position === 'vertical' && props.isMobile
      ? `
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    padding: 0 0 10px;
    flex-direction: column-reverse;
    transform: translateY(${props.transitionState === 'open' || props.transitionState === 'opening' ? '0' : '100%'});
  `
      : `
    border-left: none;
    border-radius: 0 20px 20px 0;
    padding: 0 0 0 10px;
    flex-direction: row;
    transform: translateX(${props.transitionState === 'open' || props.transitionState === 'opening' ? '0' : '-100%'});
  `}

  ${StyledLinksListItem} {
    margin: ${props => (props.position === 'vertical' && props.isMobile ? '0 0 25px' : '0 0 0 25px')};
  }
`

export const StyledLink = styled.a`
  display: block;
  height: 14px;
`
