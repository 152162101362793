import styled from 'styled-components'
import { Link } from 'gatsby'
//
import { Media } from '~utils'
import { baristaReadableColor, baristaReadableHoverColor } from '~utils/colors'

export const StyledItemLinkInner = styled.span`
  padding: 0 0 8px;
`

export const StyledItemLink = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  text-decoration: none;
  letter-spacing: 2px;
`

export const StyledItem = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  text-decoration: none;
  letter-spacing: 2px;
`

export const StyledLinkWrapper = styled.div`
  height: 100%;
`

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 0;

  a {
    color: inherit !important;
    text-decoration: none;
    display: block;
    height: 100%;

    &:hover {
      box-shadow: 0 0 60px 0 rgba(191, 191, 191, 0.5);
    }
  }

  ${StyledItemLink},
  ${StyledItem} {
    color: ${props => baristaReadableColor(props.backgroundColor)};

    ${StyledItemLinkInner} {
      border-bottom: 1px solid ${props => baristaReadableColor(props.backgroundColor)};
    }

    &:hover {
      color: ${props => baristaReadableHoverColor(props.backgroundColor)};

      ${StyledItemLinkInner} {
        border-color: ${props => baristaReadableHoverColor(props.backgroundColor)};
      }
    }
  }

  ${StyledLinkWrapper} {
    &:hover {
      ${StyledItem} {
        color: ${props => baristaReadableHoverColor(props.backgroundColor)};

        ${StyledItemLinkInner} {
          border-color: ${props => baristaReadableHoverColor(props.backgroundColor)};
        }
      }
    }
  }
`

export const StyledItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;

  ${Media({
    padding: ['24px 25px 0', null, '40px 25px 0']
  })}
`

export const StyledItemTitle = styled.h3`
  font-weight: normal;
  text-align: center;
  margin: 0 0 16px;

  ${Media({
    fontSize: ['24px', null, '28px'],
    lineHeight: ['1.5', null, '1.29'],
    letterSpacing: ['0.86px', '1px']
  })}
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
`

export const StyledItemDescription = styled.p`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 0 16px;
`

export const StyledFeaturedItemDescription = styled.p`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0 0 16px;
`
