/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import IframeResizer from 'iframe-resizer-react'
//
import { ContentfulIframeBlock } from '../../../generated/gatsby.types'
import { StyledIframeContainer } from './StyledIframe'

export const IframeBlock = ({ url }: ContentfulIframeBlock) => {
  if (!url) {
    return null
  }

  return (
    <StyledIframeContainer>
      <IframeResizer src={url} frameBorder="0" style={{ width: '1px', minWidth: '100%' }} />
    </StyledIframeContainer>
  )
}

export default IframeBlock
