import styled from 'styled-components'
//
import { Media } from '~utils'
import { baristaReadableColor } from '~utils/colors'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => baristaReadableColor(props.backgroundColor)};

  ${Media({
    padding: ['28px 0 0', null, '74px 0 20px', '84px 0 20px']
  })}
`

export const StyledTitle = styled.h2`
  font-weight: normal;
  letter-spacing: 1px;

  ${Media({
    fontSize: ['32px', null, null, '40px'],
    margin: ['0 0 24px', null, '0 0 20px', '0 0 32px']
  })}
`

export const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.5px;
  white-space: pre-wrap;

  ${Media({
    margin: ['0 0 24px', null, '0 0 16px']
  })}
`
