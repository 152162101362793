import React from 'react'
//

export const getID = (url: string): string => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  if (match && match[2] && match[2].length === 11) {
    return match[2]
  }

  return ''
}

export const getEmbedUrl = (id: string): string => `https://www.youtube-nocookie.com/embed/${id}`

export const getEmbedProps = () => {
  return `cc_lang_pref=en&hl=en&modestbranding=1&rel=0&enablejsapi=1&html5&origin=${process.env.SITE_URL}`
}

export const getEmbedAttributes = (): object => {
  return {
    frameBorder: '0',
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen: true
  }
}

interface GetEmbedSrcProps {
  url: string
  controls?: number
  autoplay?: number
  startTime?: number
  endTime?: number
}

export const getEmbedSrc = ({ url, controls = 1, autoplay = 0, startTime, endTime }: GetEmbedSrcProps) => {
  let fullUrl = `${getEmbedUrl(getID(url))}?${getEmbedProps()}&controls=${controls}&autoplay=${autoplay}`

  if (startTime) {
    fullUrl += `&${fullUrl}&start=${startTime}`
  }

  if (endTime) {
    fullUrl += `&${fullUrl}&end=${endTime}`
  }

  return fullUrl
}

interface GetEmbedIframeProps extends GetEmbedSrcProps {
  title: string | null | undefined
}

export const getEmbedIframe = ({ url, title = '', controls, autoplay, startTime, endTime }: GetEmbedIframeProps) => {
  return (
    <iframe
      title={title || ''}
      src={getEmbedSrc({ url, controls, autoplay, startTime, endTime })}
      {...getEmbedAttributes()}
    />
  )
}
