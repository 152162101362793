import React from 'react'
//
import { ContentfulMultipleContentBlock } from '~generated/gatsby.types'
import theme from '~theme'
import { StyledContainer, StyledTitle, StyledShortDescription } from './Styled'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import CTA from '../../base-components/cta'
import { baristaReadableButtonTheme } from '~utils/colors'
import CardList from '../../card-list'
import { withCard } from '../../base-components/card'

const MultipleContentBlock = ({
  id,
  title,
  shortDescription,
  backgroundColor,
  cta,
  contents
}: ContentfulMultipleContentBlock) => {
  const bgColor = backgroundColor?.colorCode || theme.colors.oatmealBrown

  const Card = withCard({ ctaTextId: 'multipleContentBlock.cta', backgroundColor: bgColor })

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer backgroundColor={bgColor}>
        <Grid>
          <Cell cols={[12, null, 5, 10]} offset={[0, null, 1]}>
            <StyledTitle>{title}</StyledTitle>
            <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>
          </Cell>
          <Cell cols={[12, null, 10, 10]} offset={[0, null, 1]} show={[false, null, true]}>
            <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} />
          </Cell>
        </Grid>
        <CardList id={id} contents={contents} Card={Card} backgroundColor={bgColor} />
        <Grid>
          <Cell cols={[12]} show={[true, null, false]}>
            <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} fullWidth />
          </Cell>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default MultipleContentBlock
