import styled from 'styled-components'
//
import { Media } from '~utils'

// eslint-disable-next-line import/prefer-default-export
export const StyledVideoEmbedded = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  ${Media({
    paddingBottom: [null, '50%', '50%', '70%']
  })}

  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
