import React from 'react'

const Twitter = () => (
  <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
    <g id="twitter-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="share-twitter" transform="translate(-134.000000, -13.000000)" fill="#242424" fillRule="nonzero">
        <path
          d="M139.031238,26.4999988 C140.9479,26.4999988 142.645813,26.0104165 144.124976,25.0312523 C145.479139,24.1562544 146.53122,22.9791737 147.281218,21.5000107 C148.010383,20.1041806 148.374966,18.6562675 148.374966,17.1562711 C148.374966,16.9687715 148.364549,16.8333553 148.343716,16.7500221 C148.989548,16.2708567 149.541629,15.6979411 149.999962,15.0312762 C149.374963,15.302109 148.749965,15.4791916 148.124966,15.5625249 C148.833298,15.1250259 149.312463,14.5208609 149.562463,13.7500292 C148.916631,14.1250283 148.218716,14.3854442 147.468718,14.5312773 C147.156219,14.2187781 146.796844,13.9687787 146.390595,13.7812791 C145.984346,13.5937796 145.541639,13.5000298 145.062474,13.5000298 C144.479142,13.5000298 143.937476,13.6458629 143.437477,13.9375288 C142.937479,14.2291946 142.541646,14.6302356 142.24998,15.1406509 C141.958315,15.6510662 141.812481,16.1979399 141.812481,16.781272 C141.812481,17.0312714 141.833315,17.2812708 141.874981,17.5312702 C140.541651,17.4687703 139.286446,17.1354376 138.109365,16.5312726 C136.932285,15.9271075 135.937495,15.1146094 135.124997,14.0937784 C134.812498,14.6146106 134.656248,15.1614843 134.656248,15.7343995 C134.656248,16.3073146 134.791665,16.8333553 135.062497,17.3125207 C135.33333,17.7916861 135.687496,18.1875186 136.124995,18.5000179 C135.604163,18.4791844 135.114581,18.3333518 134.656248,18.0625189 L134.656248,18.1250188 C134.656248,18.8958504 134.906248,19.5833488 135.406247,20.1875139 C135.906245,20.7916789 136.531244,21.177095 137.281242,21.3437611 C136.989576,21.406261 136.69791,21.4375109 136.406244,21.4375109 C136.218745,21.4375109 136.020829,21.4270944 135.812496,21.406261 C136.020829,22.0520929 136.401036,22.5885496 136.953118,23.0156321 C137.5052,23.4427146 138.145824,23.6562556 138.874988,23.6562556 C137.666658,24.5937534 136.302078,25.0625022 134.781248,25.0625022 C134.489582,25.0625022 134.229166,25.0520858 134,25.0312523 C135.52083,26.0104165 137.197909,26.4999988 139.031238,26.4999988 Z"
          id="t"
        />
      </g>
    </g>
  </svg>
)

export default Twitter
