import React from 'react'
//
import { ContentfulBaristasBlock } from '~generated/gatsby.types'
import RoughEdge from '~components/base-components/rough-edge'
import {
  StyledTitle,
  StyledShortDescription,
  StyledTopContainer,
  StyledBottomContainer,
  StyledCellLeft,
  StyledCellRight,
  StyledDivider
} from './Styled'
import { Grid, Cell } from '~components/base-components/grid'
import CTA from '../../base-components/cta'
import Barista from './Barista'

const BaristasBlock = ({ id, title, shortDescription, cta, baristas }: ContentfulBaristasBlock) => {
  if (!baristas || !baristas[0] || !baristas[1]) {
    return null
  }

  const leftBarista = baristas[0]
  const rightBarista = baristas[1]

  return (
    <div key={`component-${id}`}>
      <RoughEdge id={id} backgroundColor="#ede9e6" />
      <StyledTopContainer>
        <Grid>
          <Cell cols={[12, null, null, null, 10]} offset={[null, null, null, null, 1]}>
            <StyledTitle>{title}</StyledTitle>
            <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>
            {cta && <CTA theme="black" {...cta} />}
          </Cell>
        </Grid>
      </StyledTopContainer>
      <RoughEdge id={id} backgroundColor="#fff" />
      <StyledBottomContainer>
        <Grid>
          <StyledCellLeft cols={[12, 12, 5, 5, 5]} offset={[null, null, 1]}>
            <Barista orientation="left" {...leftBarista} />
          </StyledCellLeft>
          <StyledCellRight cols={[12, 12, 5, 5, 5]}>
            <Barista orientation="right" {...rightBarista} />
          </StyledCellRight>
        </Grid>
      </StyledBottomContainer>
      <Grid>
        <Cell>
          <StyledDivider />
        </Cell>
      </Grid>
    </div>
  )
}

export default BaristasBlock
