import React from 'react'
//
import { ContentfulContentBannerPromo } from '~generated/gatsby.types'
import theme from '~theme'
import {
  StyledContainer,
  StyledContent,
  StyledCategoryTitle,
  StyledTitle,
  StyledShortDescription,
  StyledMedia,
  StyledMediaInner
} from './Styled'
import { getSourceImage, ImageFluid } from '~utils/image'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import EmbeddedVideo from '~components/base-components/embedded-video'
import CTA from '~components/base-components/cta'
import { baristaReadableButtonTheme } from '~utils/colors'

const ContentBannerPromo = ({
  id,
  title,
  shortDescription,
  cta,
  backgroundColor,
  image,
  category,
  mediaOrientation,
  videoUrl
}: ContentfulContentBannerPromo) => {
  const alignMediaLeft = mediaOrientation === 'Left'
  const contentOrder = alignMediaLeft ? 2 : 1
  const mediaOrder = alignMediaLeft ? 1 : 2
  const mediaOffset = alignMediaLeft ? 0 : 1
  const bgColor = backgroundColor?.colorCode || theme.colors.oatmealBrown
  const parsedImage = getSourceImage(image)

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer backgroundColor={bgColor}>
        <Grid>
          <Cell offset={[0, null, 1]} cols={[12, null, 10, 4]} order={[2, null, null, contentOrder]}>
            <StyledContent backgroundColor={bgColor}>
              <StyledCategoryTitle>{category?.title}</StyledCategoryTitle>
              <StyledTitle>{title}</StyledTitle>
              <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>
              {cta && <CTA hollow theme={baristaReadableButtonTheme(bgColor)} {...cta} />}
            </StyledContent>
          </Cell>
          <Cell offset={[0, null, 1, mediaOffset]} cols={[12, null, 10, 6]} order={[1, null, null, mediaOrder]}>
            <StyledMedia>
              <StyledMediaInner className={videoUrl ? 'with-video' : 'with-image'} alignMediaLeft={alignMediaLeft}>
                {videoUrl ? (
                  <EmbeddedVideo id={`promo-${id}`} title={title} videoUrl={videoUrl} />
                ) : (
                  parsedImage && <ImageFluid image={parsedImage} alt={title || ''} />
                )}
              </StyledMediaInner>
            </StyledMedia>
          </Cell>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default ContentBannerPromo
