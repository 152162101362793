import styled from 'styled-components'
//
import { Link } from 'gatsby'
import { Media } from '~utils'
import { Cell } from '~components/base-components/grid'

export const StyledTopContainer = styled.div`
  background-color: ${props => props.theme.colors.oatmealBrown};

  ${Media({
    paddingTop: ['48px', null, null, '130px'],
    paddingBottom: ['185px', null, '155px', '155px']
  })}

  button {
    margin: 0 auto;
    ${Media({
      minWidth: ['100%', null, '160px', '160px']
    })}
  }
`

export const StyledBottomContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 20px;

  ${Media({
    paddingBottom: ['48px', null, null, '130px']
  })}
`

export const StyledTitle = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 0 auto 8px;
  outline: 0;

  ${Media({
    fontSize: ['32px', null, '40px', '40px'],
    lineHeight: [1, null, null, 1.2]
  })}
`

export const StyledShortDescription = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto 16px;
  white-space: pre-wrap;

  ${Media({
    maxWidth: ['none', null, 'none', '480px']
  })}
`

export const StyledCellLeft = styled(Cell)`
  z-index: 2;

  ${Media({
    textAlign: [null, null, 'right', 'right'],
    paddingRight: [null, null, '30px', '36px'],
    marginTop: ['-160px', null, '-80px', '-80px']
  })}
`

export const StyledCellRight = styled(Cell)`
  z-index: 2;

  ${Media({
    textAlign: [null, null, 'left', 'left'],
    paddingLeft: [null, null, '30px', '36px'],
    marginTop: ['38px', null, '-80px', '-80px']
  })}
`

export const StyledBarista = styled.div`
  text-align: center;

  ${Media({
    maxWidth: ['100%', '100%', '400px', '589px'],
    paddingBottom: ['48px 0', null, null, '130px 0']
  })}

  &.left {
    ${Media({
      marginLeft: ['none', 'none', 'auto', 'auto']
    })}
  }

  &.right {
    ${Media({
      marginRight: ['none', 'none', 'auto', 'auto']
    })}
  }
`

export const StyledPrefix = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 20px;
  letter-spacing: 2px;
  line-height: 1.83;
  text-transform: uppercase;
  margin-top: 40px;
`

export const StyledBaristaDetails = styled.h3`
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 1.29;
  margin-top: 16px;
  font-weight: normal;
`

export const StyledCta = styled(Link)`
  color: ${props => props.theme.colors.black};
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 20px;
  letter-spacing: 2px;
  line-height: 1.83;
  text-transform: uppercase;
  margin-top: 40px;
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 1px solid ${props => props.theme.colors.black};

  &:hover {
    color: ${props => props.theme.colors.darkGrey};
    border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  }
`

export const StyledDivider = styled.div`
  display: block;
  height: 1px;
  padding-bottom: 10px;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  ${props =>
    Media({
      borderColor: ['#979797', '#979797', props.theme.colors.black, props.theme.colors.black]
    })}
`
