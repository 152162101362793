import styled from 'styled-components'
//
import { Media } from '~utils'

// eslint-disable-next-line import/prefer-default-export
export const StyledDiv = styled.div`
  ${Media({
    height: ['20px', '56px', '46px', '46px']
  })}
`
