import React from 'react'
import { FormattedMessage } from 'react-intl'
//
import { Flex } from 'rebass'
import {
  ContentfulCategory,
  ContentfulBarista,
  ContentfulTipQuoteTextTextNode,
  ContentfulImage,
  ContentfulTipDescriptionRichTextNode,
  ContentfulSettingsGenericTipQuoteTextNode,
  ContentfulSettingsGenericTipTextRichTextNode
} from '~generated/gatsby.types'
import theme from '~theme'
import { getSourceImage, ImageFluid } from '~utils/image'
import { Grid, Cell } from '../../base-components/grid'
import RoughEdge from '../../base-components/rough-edge'
import RichText from '../../base-components/rich-text'
import {
  StyledContainer,
  StyledImageContainer,
  StyledContentContainer,
  StyledBaristaContent,
  StyledDescription,
  StyledCategoryTitle,
  StyledBaristaName,
  StyledPrefix,
  StyledQuote,
  StyledSignature,
  StyledLocation,
  StyledDescriptionInner,
  StyledMedia,
  StyledMediaInner
} from './Styled'

type Props = {
  image?: ContentfulImage | null
  category?: ContentfulCategory | null
  prefix?: string | null
  barista?: ContentfulBarista | null | undefined
  quoteText?: ContentfulTipQuoteTextTextNode | null | undefined
  description?: ContentfulTipDescriptionRichTextNode | null | undefined
  genericTipQuote?: ContentfulSettingsGenericTipQuoteTextNode | null | undefined
  genericTipText?: ContentfulSettingsGenericTipTextRichTextNode | null | undefined
  genericTipImage?: ContentfulImage | null | undefined
  variant?: string
}

const Tip = ({
  id,
  image,
  category,
  description,
  quoteText,
  barista,
  prefix,
  genericTipImage,
  genericTipQuote,
  genericTipText,
  variant = 'primary'
}: Props) => {
  const parsedImage = getSourceImage(image || genericTipImage)

  const Content = (
    <>
      <StyledCategoryTitle>{category?.title || prefix}</StyledCategoryTitle>
      <StyledQuote>&quot;{quoteText?.quoteText || genericTipQuote?.genericTipQuote}&quot;</StyledQuote>
      <StyledSignature>{barista?.title}</StyledSignature>
      <StyledBaristaName>{barista?.title}</StyledBaristaName>
      <StyledLocation>{barista?.location}</StyledLocation>
    </>
  )

  return (
    <>
      <RoughEdge id={id} backgroundColor={theme.colors.mustard} />
      <StyledContainer>
        {variant === 'primary' ? (
          <Grid>
            <Cell cols={[12, null, 10]} offset={[0, null, 1]}>
              <StyledContentContainer>
                <StyledImageContainer>{parsedImage && <ImageFluid image={parsedImage} />}</StyledImageContainer>
                <StyledBaristaContent>{Content}</StyledBaristaContent>
                <StyledDescription>
                  <StyledDescriptionInner>
                    <StyledPrefix>
                      <FormattedMessage id="tip.prefix" />
                    </StyledPrefix>
                    <RichText data={description?.json || genericTipText?.json} />
                  </StyledDescriptionInner>
                </StyledDescription>
              </StyledContentContainer>
            </Cell>
          </Grid>
        ) : (
          <Grid>
            <Cell offset={[0, null, 1]} cols={[12, null, 10, 4]} order={[2, null, null, 1]}>
              <Flex height="100%" flexDirection="column" justifyContent="center" alignItems="center">
                {Content}
              </Flex>
            </Cell>
            <Cell offset={[0, null, 1]} cols={[12, null, 10, 6]} order={[1, null, null, 2]}>
              <StyledMedia>
                <StyledMediaInner>{parsedImage && <ImageFluid image={parsedImage} />}</StyledMediaInner>
              </StyledMedia>
            </Cell>
          </Grid>
        )}
      </StyledContainer>
    </>
  )
}

export default Tip
