import React from 'react'
//
import { ContentfulPaperformBlock } from '~generated/gatsby.types'
import RoughEdge from '~components/base-components/rough-edge'
import { Grid, Cell } from '~components/base-components/grid'
import { StyledContainer, StyledContent, StyledForm } from './Styled'

const PaperformBlock = ({ id, form, backgroundColor }: ContentfulPaperformBlock) => {
  if (!form) {
    return null
  }

  const [loaded, setLoaded] = React.useState(false)

  const loadPaperFormScript = (): void => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.setAttribute('src', 'https://paperform.co/__embed')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')

    head.appendChild(script)
  }

  React.useEffect(() => {
    if (!loaded) {
      loadPaperFormScript()
      setLoaded(true)
    }
  }, [loaded])

  const bgColor = backgroundColor?.colorCode || '#ede9e6'

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer key={`component-${id}`} backgroundColor={bgColor}>
        <Grid>
          <Cell cols={[12, null, null, null, 10]} offset={[null, null, null, null, 1]}>
            <StyledContent backgroundColor={bgColor}>
              <StyledForm data-prefill-inherit="1" data-paperform-id={form} />
            </StyledContent>
          </Cell>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default PaperformBlock
