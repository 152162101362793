import React from 'react'
import { FormattedMessage, IntlContext } from 'react-intl'
//
import { ContentfulBarista } from '~generated/gatsby.types'
import { StyledBarista, StyledPrefix, StyledBaristaDetails, StyledCta } from './Styled'
import { getSourceImage, ImageFluid } from '~utils/image'
import { buildSlug } from '~utils/link'

interface BaristaProps extends ContentfulBarista {
  orientation: string
}

const Barista = ({ orientation, id, slug, title, image, position, location }: BaristaProps) => {
  const sourceImage = getSourceImage(image)
  const intl = React.useContext(IntlContext)
  const to = buildSlug({ id, slug, __typename: 'barista' })

  return (
    <StyledBarista className={orientation}>
      <ImageFluid image={sourceImage} alt={title || ''} />
      <StyledPrefix>
        <FormattedMessage id="baristasBlock.prefix" />
      </StyledPrefix>
      <StyledBaristaDetails>
        {title}
        <br />
        {position}
        <br />
        {location}
      </StyledBaristaDetails>
      <StyledCta to={to}>{intl.formatMessage({ id: 'baristasBlock.cta' })}</StyledCta>
    </StyledBarista>
  )
}

export default Barista
