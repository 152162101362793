import styled from 'styled-components'
//
import { Media } from '~utils'
import { largeBreakpoint } from '~utils/breakpoints'
import { Grid } from '../../../base-components/grid'

export const StyledContainer = styled.div`
  position: relative;

  ${Media({
    marginBottom: [null, null, '-50px']
  })}
`

export const StyledContainerInner = styled.div`
  background-color: ${props => props.theme.colors.baristaBrown};
`

export const StyledSlideContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${props => props.theme.colors.baristaBrown};
`

export const StyledContent = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  ${Media({
    padding: ['40px 0 100px', null, null, '0 0 100px 0'],
    minHeight: [null, null, null, 'calc(100vh - 140px)'],
    justifyContent: ['flex-start', null, null, 'center']
  })}
`

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${Media({
    maxHeight: ['350px', null, '450px', 'none']
  })}
`

export const StyledMediaInner = styled.div`
  overflow: hidden;
  height: 100%;

  ${Media({
    position: ['relative', null, null, 'absolute'],
    left: ['-1.5rem', null, null, '0'],
    width: ['100vw', null, null, 'calc(50vw - 0.75rem)']
  })}

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    margin: 0;
  }
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 20px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const StyledTitle = styled.h1`
  font-weight: normal;
  margin: 0 0 24px;
  letter-spacing: 1px;

  ${Media({
    fontSize: ['40px', null, null, '60px'],
    lineHeight: ['50px', null, null, '70px']
  })}

  @media (max-height: 800px) and (min-width: ${largeBreakpoint}px) {
    line-height: 60px;
  }
`

export const StyledShortDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 24px;
  letter-spacing: 0.5px;
`

export const StyledNavigationGrid = styled(Grid)`
  position: relative;
  z-index: 1;

  ${Media({
    bottom: ['136px', null, null, '80px']
  })}
`

interface StyledNavigationButtonProps {
  direction: string
}

export const StyledNavigationButton = styled.button<StyledNavigationButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  transform: rotate(${props => (props.direction === 'right' ? '180deg' : '0deg')});
  position: relative;
  top: ${props => (props.direction === 'right' ? '-3px' : '0')};
  margin: 0 24px 0 0;
  padding: 0;
  outline: 0;
`

export const StyledPaginationGrid = styled(Grid)`
  position: absolute;
  top: 300px;
  z-index: 1;

  .swiper-pagination-bullet {
    margin-right: 2px;
    width: 18px;
    height: 18px;
    outline: 0;
    background-color: transparent;
    opacity: 1;
    border-radius: 0px;
    position: relative;

    &:last-child {
      margin: 0;
    }

    > span {
      position: absolute;
      top: 6px;
      left: 4px;
      display: inline-block;
      padding: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 1;
      background-color: ${props => props.theme.colors.white};
    }
  }

  .swiper-pagination-bullet-active {
    > span {
      background-color: ${props => props.theme.colors.baristaBrown};
    }
  }
`
