import styled from 'styled-components'
//
import { Media } from '~utils'
import { baristaReadableColor } from '~utils/colors'
import CTA from '../../../base-components/cta'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  overflow: hidden;
`

interface StyledContentProps {
  backgroundColor: string
}

export const StyledContent = styled.div<StyledContentProps>`
  color: ${props => baristaReadableColor(props.backgroundColor)};
  display: flex;
  flex-direction: column;

  ${Media({
    padding: ['40px 0 20px', null, null, '30% 0'],
    justifyContent: ['flex-start', null, null, 'center']
  })}
`

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

interface StyledMediaInnerProps {
  alignMediaLeft: boolean
}

export const StyledMediaInner = styled.div<StyledMediaInnerProps>`
  ${props =>
    Media({
      position: ['relative', null, null, 'absolute'],
      left: ['-1.5rem', null, null, props.alignMediaLeft ? 'auto' : '0'],
      right: ['auto', null, null, props.alignMediaLeft ? '0' : 'auto'],
      height: ['auto', null, null, '100%'],
      width: ['100vw', null, null, 'calc(50vw - 0.75rem)'],
      overflow: ['visible', null, null, 'hidden']
    })}

  .gatsby-image-wrapper {
    ${Media({
      height: ['auto', null, null, '100%']
    })}
  }
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const StyledTitle = styled.h1`
  font-weight: normal;
  margin: 0 0 24px;
  letter-spacing: 1px;

  ${Media({
    fontSize: ['40px', null, null, '60px'],
    lineHeight: ['50px', null, null, '70px']
  })}
`

export const StyledShortDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 24px;
  letter-spacing: 0.5px;
  white-space: pre-wrap;
`

export const StyledButtonContainer = styled.div`
  display: flex;

  ${Media({
    flexDirection: ['column', null, 'row', 'column']
  })}
`

export const StyledCTA = styled(CTA)`
  ${Media({
    margin: ['0 0 24px', null, '0 33px 0 0', '0 0 24px']
  })}
`
