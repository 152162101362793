/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useRef, memo, useReducer } from 'react'
import { Swiper, Navigation, Pagination, A11y, Lazy } from 'swiper/js/swiper.esm'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'
//
import { smallBreakpoint, mediumBreakpoint } from '~utils/breakpoints'
import { useCurrentBreakpoint } from '~utils/hooks'
import { Cell } from '../base-components/grid'
import { StyledContainer, StyledCarouselGrid, StyledCarouselItem } from './Styled'
import NavigationButton from './NavigationButton'

export { default as ArrowNavigationButtons } from './ArrowNavigationButtons'

const defaultWidths = ['auto', null, '40vw', '18vw', '15vw']
const articleWidths = ['auto', null, '40vw', '20vw', '17vw']

type stringOrNull = string | null

type areEqualProps = {
  currentBreakpoint: number
}

const areEqual = (prevProps: areEqualProps, nextProps: areEqualProps) =>
  prevProps.currentBreakpoint === nextProps.currentBreakpoint

type MemoizedSwiperProps = {
  swiperOptions: any
  contents: any[]
  currentBreakpoint: number
  Card: React.ElementType
  widths?: Array<stringOrNull>
}

const MemoizedSwiper = memo(
  ({ swiperOptions, contents, Card, widths = defaultWidths }: MemoizedSwiperProps) => (
    <ReactIdSwiperCustom {...swiperOptions}>
      {contents?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledCarouselItem key={`${item?.id}-${index}`} widths={widths}>
          <Card item={item} />
        </StyledCarouselItem>
      ))}
    </ReactIdSwiperCustom>
  ),
  areEqual
)

const reducer = (state: any, action: any) => ({
  ...state,
  ...action
})

export type CarouselProps = {
  id: string
  contents: any[] | null | undefined
  backgroundColor: string
  Card: React.ElementType
  onChange?: Function
  widths?: Array<stringOrNull>
  articleSize?: boolean
}

const Carousel = ({ id, contents, backgroundColor, Card, onChange, widths, articleSize }: CarouselProps) => {
  if (!contents) {
    return null
  }

  const [swiperState, dispatchSwiperState] = useReducer(reducer, {
    initialised: false,
    isBeginning: true,
    isEnd: false
  })

  useEffect(() => {
    if (onChange) {
      onChange(swiperState)
    }
  }, [onChange, swiperState])

  const currentBreakpoint = useCurrentBreakpoint()

  const swiperOptions = {
    Swiper,
    modules: [Navigation, Pagination, A11y, Lazy],
    ...(currentBreakpoint > smallBreakpoint && {
      slidesPerView: 'auto'
    }),
    lazy: {
      loadPrevNext: true
    },
    navigation: {
      nextEl: `#right-navigation-button-${id}`,
      prevEl: `#left-navigation-button-${id}`
    },
    spaceBetween: 104,
    rebuildOnUpdate: true,
    on: {
      init() {
        dispatchSwiperState({
          initialised: true,
          // @ts-ignore
          isBeginning: this.isBeginning,
          // @ts-ignore
          isEnd: this.isEnd
        })
      },
      transitionEnd() {
        dispatchSwiperState({
          // @ts-ignore
          isBeginning: this.isBeginning,
          // @ts-ignore
          isEnd: this.isEnd
        })
      }
    }
  }

  const { isBeginning, isEnd } = swiperState
  const containerRef = useRef(null)

  return (
    <StyledContainer>
      <StyledCarouselGrid ref={containerRef}>
        {currentBreakpoint < mediumBreakpoint && (
          <>
            <NavigationButton
              backgroundColor={backgroundColor}
              direction="left"
              id={id}
              disabled={isBeginning}
              containerRef={containerRef}
              swiperState={swiperState}
            />
            <NavigationButton
              backgroundColor={backgroundColor}
              direction="right"
              id={id}
              disabled={isEnd}
              containerRef={containerRef}
              swiperState={swiperState}
            />
          </>
        )}
        <Cell cols={[10, null, 11]} offset={[1]}>
          <MemoizedSwiper
            swiperOptions={articleSize ? { ...swiperOptions, spaceBetween: 180 } : swiperOptions}
            contents={contents}
            currentBreakpoint={currentBreakpoint}
            Card={Card}
            widths={articleSize ? articleWidths : widths}
          />
        </Cell>
      </StyledCarouselGrid>
    </StyledContainer>
  )
}

export default Carousel
