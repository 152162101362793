import React from 'react'
//
import { ContentfulTextBlock } from '~generated/gatsby.types'
import { StyledTextBlock } from './Styled'
import RoughEdge from '~components/base-components/rough-edge'
import RichText from '~components/base-components/rich-text'

const TextBlock = ({ id, text }: ContentfulTextBlock) => {
  return (
    <>
      <RoughEdge id={id} backgroundColor="white" />
      <StyledTextBlock key={`component-${id}`}>
        <RichText data={text?.json} />
      </StyledTextBlock>
    </>
  )
}

export default TextBlock
