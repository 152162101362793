import { darken, readableColor } from 'polished'
//
import theme from '~theme'

export const baristaReadableColor = (
  bgColor: string,
  lightColor = theme.colors.white,
  darkColor = theme.colors.black
) => readableColor(darken(0.2, bgColor), lightColor, darkColor, true)

export const baristaReadableButtonTheme = (bgColor: string) => {
  const color = readableColor(darken(0.2, bgColor), theme.colors.white, theme.colors.black, true)

  return color === '#fff' ? 'white' : 'black'
}

export const baristaReadableHoverColor = (bgColor: string) => {
  const color = baristaReadableColor(bgColor)

  return color === '#fff' ? theme.colors.oatmealBrown : theme.colors.darkGrey
}
