import React from 'react'
//
import { ContentfulContentBannerTextAndImages } from '~generated/gatsby.types'
import theme from '~theme'
import { getSourceImage, ImageFluid } from '~utils/image'
import { baristaReadableButtonTheme } from '~utils/colors'
import RoughEdge from '../../base-components/rough-edge'
import CTA from '../../base-components/cta'
import {
  StyledContainer,
  StyledTitle,
  StyledContentContainer,
  StyledImageRow,
  StyledImageContainer,
  StyledTextContainer
} from './Styled'

const BannerTextAndImages = ({
  id,
  shortDescription,
  backgroundColor,
  imageLeft,
  imageRight,
  cta
}: ContentfulContentBannerTextAndImages) => {
  const bgColor = backgroundColor?.colorCode || theme.colors.oatmealBrown
  const parsedImageLeft = getSourceImage(imageLeft)
  const parsedImageRight = getSourceImage(imageRight)

  return (
    <>
      <RoughEdge id={id} backgroundColor={bgColor} />
      <StyledContainer backgroundColor={bgColor}>
        <StyledContentContainer>
          <StyledImageRow align="left">
            <StyledImageContainer>
              <ImageFluid image={parsedImageLeft} />
            </StyledImageContainer>
          </StyledImageRow>
          <StyledTextContainer>
            <StyledTitle backgroundColor={bgColor}>{shortDescription?.shortDescription}</StyledTitle>
            {cta && <CTA theme={baristaReadableButtonTheme(bgColor)} {...cta} fullWidth />}
          </StyledTextContainer>
          <StyledImageRow align="right">
            <StyledImageContainer>
              <ImageFluid image={parsedImageRight} />
            </StyledImageContainer>
          </StyledImageRow>
        </StyledContentContainer>
      </StyledContainer>
    </>
  )
}

export default BannerTextAndImages
