import styled from 'styled-components'
import { StyledBlockTextContainer } from '../stacked-blocks/Styled'
//
import { Media } from '~utils'

export const StyledContainer = styled.div`
  ${Media({
    padding: ['28px 0 42px', null, '86px 0']
  })}

  .featured-wrapper {
    ${Media({
      marginBottom: ['2rem', null, null, '6.5rem']
    })}
  }

  ${StyledBlockTextContainer}.featured {
    margin-left: 0;

    h3 {
      text-align: left;
    }
  }
`

interface StyledTitleProps {
  readonly centered?: boolean
}

export const StyledTitle = styled.h2<StyledTitleProps>`
  font-weight: normal;
  letter-spacing: 0.8px;
  color: ${props => props.theme.colors.black};
  ${props =>
    props.centered &&
    Media({
      textAlign: ['left', null, null, 'center']
    })}

  ${Media({
    fontSize: ['32px', null, null, '40px'],
    letterSpacing: ['0.8px', null, null, '1px'],
    margin: ['0', null, null, '0 0 28px']
  })}
`

export const StyledBaristaCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 0;

  a {
    text-decoration: none;

    &:hover {
      box-shadow: 0 0 60px 0 rgba(191, 191, 191, 0.5);

      button {
        background-color: ${props => props.theme.colors.black};

        span {
          color: ${props => props.theme.colors.white};
        }
      }
    }
  }
`

export const StyledBaristaCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  ${Media({
    padding: ['25px', null, '40px 25px']
  })}
`

export const StyledBaristaCardTitle = styled.h3`
  font-weight: normal;
  text-align: center;
  color: ${props => props.theme.colors.black};

  ${Media({
    fontSize: ['24px', null, '28px'],
    lineHeight: ['1.5', null, '1.29'],
    letterSpacing: ['0.86px', '1px'],
    margin: ['0 0 42px', null, '0 0 36px']
  })}
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.black};
`

export const StyledBaristaCardSignature = styled.p`
  color: ${props => props.theme.colors.black};
  text-align: center;
  transform: rotate(-9deg);
  font-family: Caveat;
  font-size: 40px;
  letter-spacing: -0.89px;

  ${Media({
    margin: ['0 0 54px', null, '0 0 48px']
  })}
`

export const StyledBaristaCardName = styled.p`
  color: ${props => props.theme.colors.black};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${Media({
    margin: ['0 0 8px', null, '0 0 16px']
  })}
`

export const StyledBaristaCardLocation = styled.p`
  color: ${props => props.theme.colors.black};
  text-align: center;
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 2px;

  ${Media({
    margin: ['0 0 40px', null, '0 0 16px']
  })}
`

export const StyledFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.fontWeights.heading};
  text-transform: uppercase;
  letter-spacing: 0.125rem;

  > span {
    margin-right: 1rem;

    ${Media({
      marginBottom: ['1.25rem', null, null, '0'],
      display: ['block', null, null, 'inline-block'],
      fontSize: ['1rem', '1rem', '0.75rem'],
      width: ['100%', null, null, 'auto']
    })}
  }

  ${Media({
    marginBottom: ['6.5rem', null, '4rem', '6.5rem']
  })}
`

export const StyledFilterButton = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.fontWeights.heading};
  border-radius: 1e5px;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  cursor: pointer;
  padding: 0 1rem;
  margin-right: 1rem;

  ${Media({
    fontSize: ['1rem', '1rem', '0.75rem'],
    marginBottom: ['1rem', null, null, '0']
  })}

  &:hover,
  &.selected {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.black};
  }
`
