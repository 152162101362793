import React from 'react'
//
import {
  StyledContainer,
  StyledContent,
  StyledMedia,
  StyledMediaInner,
  StyledCategoryTitle,
  StyledTitle,
  StyledShortDescription,
  StyledButtonContainer,
  StyledCTA
} from './Styled'
import { Grid, Cell } from '../../../base-components/grid'
import theme from '~theme'
import {
  ContentfulImage,
  ContentfulCategory,
  ContentfulNavigationElement,
  ContentfulSecondaryHeroShortDescriptionTextNode,
  ContentfulRecipeShortDescriptionTextNode,
  ContentfulBaristaColors
} from '~generated/gatsby.types'
import { getSourceImage, ImageFluid } from '~utils/image'
import { baristaReadableButtonTheme } from '~utils/colors'
import Social from '~components/social'

type Props = {
  title?: string | null
  shortDescription:
    | ContentfulSecondaryHeroShortDescriptionTextNode
    | ContentfulRecipeShortDescriptionTextNode
    | undefined
    | null
  backgroundColor?: ContentfulBaristaColors | null
  image?: ContentfulImage | null
  category?: ContentfulCategory | null
  prefix?: string | null
  cta?: ContentfulNavigationElement | null
  mediaOrientation?: string
  showSocialShare?: boolean
}

const SecondaryHero = ({
  title,
  backgroundColor,
  image,
  prefix,
  category,
  shortDescription,
  cta,
  mediaOrientation,
  showSocialShare
}: Props) => {
  const alignMediaLeft = mediaOrientation === 'Left'
  const contentOrder = alignMediaLeft ? 2 : 1
  const mediaOrder = alignMediaLeft ? 1 : 2
  const mediaOffset = alignMediaLeft ? 0 : 2
  const parsedImage = getSourceImage(image)
  const bgColor = backgroundColor?.colorCode || theme.colors.oatmealBrown

  return (
    <StyledContainer backgroundColor={bgColor}>
      <Grid>
        <Cell offset={[0, null, 1]} cols={[12, null, 10, 5, 3]} order={[2, null, null, contentOrder]}>
          <StyledContent backgroundColor={bgColor}>
            <StyledCategoryTitle>{prefix || category?.title}</StyledCategoryTitle>
            <StyledTitle>{title}</StyledTitle>
            <StyledShortDescription>{shortDescription?.shortDescription}</StyledShortDescription>
            <StyledButtonContainer>
              {cta && <StyledCTA theme={baristaReadableButtonTheme(bgColor)} {...cta} fullWidth />}
              {showSocialShare && <Social />}
            </StyledButtonContainer>
          </StyledContent>
        </Cell>
        <Cell offset={[0, null, null, 0, mediaOffset]} cols={[12, null, null, 6]} order={[1, null, null, mediaOrder]}>
          <StyledMedia>
            <StyledMediaInner alignMediaLeft={alignMediaLeft}>
              {parsedImage && <ImageFluid image={parsedImage} alt={title || ''} />}
            </StyledMediaInner>
          </StyledMedia>
        </Cell>
      </Grid>
    </StyledContainer>
  )
}

export default SecondaryHero
