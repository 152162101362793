import styled from 'styled-components'
//
import { Media } from '~utils'
import { baristaReadableColor } from '~utils/colors'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  ${Media({
    padding: ['48px 0', null, null, '130px 0']
  })}
`

interface StyledContentProps {
  backgroundColor: string
}

export const StyledContent = styled.div<StyledContentProps>`
  color: ${props => baristaReadableColor(props.backgroundColor)};

  width: 100%;
  margin: 0 auto;

  ${Media({
    maxWidth: [null, null, null, '1245px']
  })}

  button {
    margin: 0 auto;
    ${Media({
      minWidth: ['100%', null, '160px', '160px']
    })}
  }

  .bzfy-t-feed-tile-grid {
    background-color: ${props => props.backgroundColor || props.theme.colors.oatmealBrown} !important;
  }

  .bzfy-feed {
    z-index: 0;
  }
`

export const StyledTitle = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 0 auto 32px;
  outline: 0;

  ${Media({
    fontSize: ['32px', null, '60px', '60px'],
    lineHeight: [1, null, null, 1.2]
  })}
`

export const StyledShortDescription = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto 16px;
  white-space: pre-wrap;

  ${Media({
    maxWidth: ['none', null, '480px', '480px']
  })}
`

export const StyledInstagramWrapper = styled.div`
  ${Media({
    marginTop: ['48px', null, null, '136px'],
    maxWidth: [null, null, null, '1245px'],
    minHeight: ['none', null, null, '834px']
  })}
`
