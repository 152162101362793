import React, { useMemo } from 'react'
import { Swiper, Navigation, Pagination, A11y, Lazy } from 'swiper/js/swiper.esm'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'
//
import { Flex } from 'rebass'
import { ContentfulPrimaryHeroCarousel } from '~generated/gatsby.types'
import { getSourceImage, ImageFluid } from '~utils/image'
import {
  StyledContainer,
  StyledContainerInner,
  StyledSlideContainer,
  StyledContent,
  StyledMedia,
  StyledMediaInner,
  StyledCategoryTitle,
  StyledTitle,
  StyledShortDescription,
  StyledNavigationButton,
  StyledNavigationGrid,
  StyledPaginationGrid
} from './Styled'
import { Grid, Cell } from '../../../base-components/grid'
import CTA from '../../../base-components/cta'
import Arrow from '../../../base-components/arrow'
import { getRandomIndex } from '~utils/common'

const PrimaryHeroCarousel = ({ id, slides = [] }: ContentfulPrimaryHeroCarousel) => {
  const contents = slides?.map(slide => (
    <StyledSlideContainer key={slide?.id}>
      <Grid>
        <Cell offset={[0, null, 1]} cols={[12, null, 10, 5, 4]} order={[2, null, null, 1]}>
          <StyledContent>
            <StyledCategoryTitle>{slide?.category?.title}</StyledCategoryTitle>
            <StyledTitle>{slide?.title}</StyledTitle>
            <StyledShortDescription>{slide?.shortDescription?.shortDescription}</StyledShortDescription>
            {slide?.cta && <CTA theme="white" hollow {...slide?.cta} fullWidth />}
          </StyledContent>
        </Cell>
        <Cell offset={[0, null, null, 0, 1]} cols={[12, null, null, 6]} order={[1, null, null, 2]}>
          <StyledMedia>
            <StyledMediaInner>
              <ImageFluid
                image={getSourceImage(slide?.image)}
                alt={slide?.title || ''}
                fadeIn={false}
                loading="eager"
                placeholderStyle={{ visibility: 'hidden' }}
              />
            </StyledMediaInner>
          </StyledMedia>
        </Cell>
      </Grid>
    </StyledSlideContainer>
  ))

  const initialSlide = useMemo(() => getRandomIndex(slides), [])

  const swiperParams = {
    Swiper,
    modules: [Navigation, Pagination, A11y, Lazy],
    loop: true,
    rebuildOnUpdate: true,
    lazy: {
      loadPrevNext: true
    },
    navigation: {
      nextEl: `#right-navigation-button-${id}`,
      prevEl: `#left-navigation-button-${id}`
    },
    pagination: {
      el: `#swiper-pagination-${id}`,
      clickable: true,
      renderBullet: (index: number, className: string) =>
        `<span data-page="${index}" class="${className}"><span></span></span>`
    },
    initialSlide
  }

  return (
    <StyledContainer>
      <StyledContainerInner>
        <ReactIdSwiperCustom {...swiperParams}>{contents}</ReactIdSwiperCustom>
      </StyledContainerInner>
      <StyledNavigationGrid>
        <Cell offset={[9, null, null, 1]} show={[false, false, true]}>
          <StyledNavigationButton direction="left" id={`left-navigation-button-${id}`}>
            <Arrow />
          </StyledNavigationButton>
          <StyledNavigationButton direction="right" id={`right-navigation-button-${id}`}>
            <Arrow />
          </StyledNavigationButton>
        </Cell>
      </StyledNavigationGrid>
      <StyledPaginationGrid justify="center">
        <Cell show={[true, true, false]}>
          <Flex justifyContent="center">
            <div id={`swiper-pagination-${id}`} />
          </Flex>
        </Cell>
      </StyledPaginationGrid>
    </StyledContainer>
  )
}

export default PrimaryHeroCarousel
