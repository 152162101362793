import React from 'react'
//
import { StyledVideoEmbedded } from './Styled'
import { getEmbedIframe } from '~utils/video'
import { useIntersect } from '~utils/hooks'

type Props = {
  id: string
  videoUrl: string
  title?: string | null | undefined
  autoplay?: number
  startTime?: number
  endTime?: number
}

const EmbeddedVideo = ({ id, videoUrl, title, autoplay, startTime, endTime }: Props) => {
  const [showVideo, setShowVideo] = React.useState(false)
  const [ref, entry] = useIntersect({ rootMargin: '200px 0px', threshold: 0.25 })

  React.useEffect(() => {
    if (!showVideo && entry && entry.isIntersecting) {
      setShowVideo(true)
    }
  }, [entry])

  if (!videoUrl) {
    return null
  }

  return (
    <div ref={ref} key={`wrapper-${id}-${videoUrl}-${autoplay}-${startTime}-${endTime}`}>
      {showVideo ? (
        <StyledVideoEmbedded key={`${id}-${videoUrl}-${autoplay}-${startTime}-${endTime}`}>
          {getEmbedIframe({ url: videoUrl, title, autoplay, startTime, endTime })}
        </StyledVideoEmbedded>
      ) : null}
    </div>
  )
}

export default EmbeddedVideo
