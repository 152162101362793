import React from 'react'
//
import { useCurrentBreakpoint } from '~utils/hooks'
import Arrow from '../base-components/arrow'
import { StyledArrowNavigationButtonsContainer, StyledArrowNavigationButton } from './Styled'
import { largeBreakpoint } from '~utils/breakpoints'

type ArrowNavigationButtons = {
  id: string
  carouselState: any
  backgroundColor: string
  justify?: string
}

const ArrowNavigationButtons = ({
  id,
  carouselState,
  backgroundColor,
  justify = 'flex-end'
}: ArrowNavigationButtons) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const { isBeginning, isEnd } = carouselState
  const doNotRender = currentBreakpoint < largeBreakpoint || (isBeginning && isEnd)

  return doNotRender ? null : (
    <StyledArrowNavigationButtonsContainer justify={justify}>
      <StyledArrowNavigationButton
        disabled={isBeginning}
        backgroundColor={backgroundColor}
        direction="left"
        id={`left-navigation-button-${id}`}
      >
        <Arrow />
      </StyledArrowNavigationButton>
      <StyledArrowNavigationButton
        disabled={isEnd}
        backgroundColor={backgroundColor}
        direction="right"
        id={`right-navigation-button-${id}`}
      >
        <Arrow />
      </StyledArrowNavigationButton>
    </StyledArrowNavigationButtonsContainer>
  )
}

export default ArrowNavigationButtons
