import styled from 'styled-components'
//
import { Media } from '~utils'
import { getColumnWidth } from '../../base-components/grid'
import { baristaReadableColor } from '~utils/colors'

interface StyledContainerProps {
  backgroundColor: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${props => props.backgroundColor};
  overflow: hidden;

  ${Media({
    padding: ['48px 0', null, null, '65px 0 76px']
  })}
`

interface StyledContentProps {
  backgroundColor: string
}

export const StyledContent = styled.div<StyledContentProps>`
  color: ${props => baristaReadableColor(props.backgroundColor)};

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Media({
    minHeight: ['none', null, null, null, '800px']
  })}
`

export const StyledCategoryTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const StyledTitle = styled.h1`
  font-weight: normal;
  margin: 0 0 24px;
  outline: 0;

  ${Media({
    fontSize: ['40px', null, null, '60px'],
    lineHeight: ['50px', null, null, '70px']
  })}
`

export const StyledShortDescription = styled.p`
  font-size: 16px;
  line-height: 32px;
  margin: 0 0 24px;
  white-space: pre-wrap;

  ${Media({
    maxWidth: ['none', null, null, '480px']
  })}
`

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${Media({
    marginBottom: ['40px', null, null, '0']
  })}
`

interface StyledMediaInner {
  alignMediaLeft: boolean
}

export const StyledMediaInner = styled.div<StyledMediaInner>`
  ${props =>
    Media({
      position: ['relative', null, null, 'absolute'],
      height: ['auto', null, null, '100%'],
      maxHeight: [null, null, '416px', '100%'],
      width: ['calc(100vw - 1.5rem)', null, `calc(100vw - ${getColumnWidth(1)}vw - 1.5rem)`, 'calc(50vw - 0.75rem)'],
      overflow: ['visible', null, 'hidden'],
      left: ['0', null, null, props.alignMediaLeft ? 'auto' : '0'],
      right: ['auto', null, null, props.alignMediaLeft ? '0' : 'auto']
    })}

  > div {
    ${props =>
      Media({
        position: ['relative', null, null, 'absolute'],
        height: ['auto', null, null, '100%'],
        maxHeight: [null, null, '416px', '100%'],
        width: ['calc(100vw - 1.5rem)', null, `calc(100vw - ${getColumnWidth(1)}vw - 1.5rem)`, 'calc(50vw - 0.75rem)'],
        overflow: ['visible', null, 'hidden'],
        left: ['0', null, null, props.alignMediaLeft ? 'auto' : '0'],
        right: ['auto', null, null, props.alignMediaLeft ? '0' : 'auto']
      })}
  }

  .gatsby-image-wrapper {
    ${Media({
      height: ['auto', null, null, '100%']
    })}
  }

  &.with-video > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  iframe {
    height: 100%;
    ${Media({
      maxHeight: [null, null, '416px', '100%']
    })}
  }
`
