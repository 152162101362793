import React from 'react'
import { Flex } from 'rebass'
//
import { IntlContext } from 'react-intl'
import { ContentfulNavigationElement } from '~generated/gatsby.types'
import { buildSlug, NavLink } from '~utils/link'
import Button from '../button'

interface Props extends Omit<ContentfulNavigationElement, 'id' | 'children' | 'internal'> {
  id?: string | null | undefined
  theme?: string
  fullWidth?: boolean
  hollow?: boolean
  noLink?: boolean
  className?: string
  ariaLabel?: string
  accessibleText?: string | null | undefined
}

const CTA = ({
  content,
  title,
  externalUrl,
  accessibleText,
  ariaLabel,
  fullWidth,
  hollow,
  className,
  noLink = false,
  theme = 'black'
}: Props) => {
  const linkProps: Record<string, any> = {}
  const intl = React.useContext(IntlContext)

  if (accessibleText || ariaLabel) {
    linkProps['aria-label'] = accessibleText || ariaLabel
  } else {
    linkProps['aria-label'] = intl.formatMessage({ id: 'a11y.cta.fallback' }, { title })
  }

  if (externalUrl) {
    return (
      <Button theme={theme} fullWidth={fullWidth} hollow={hollow} className={className} title={linkProps['aria-label']}>
        <a href={externalUrl} target="_blank" rel="noreferrer nofollow" {...linkProps}>
          <Flex justifyContent="center" alignItems="center" height="100%">
            <span>{title}</span>
          </Flex>
        </a>
      </Button>
    )
  }

  if (content && content.slug) {
    return (
      <Button theme={theme} fullWidth={fullWidth} hollow={hollow} className={className} title={linkProps['aria-label']}>
        {noLink ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <span>{title}</span>
          </Flex>
        ) : (
          <NavLink to={buildSlug(content)} ariaLabel={linkProps['aria-label']}>
            <Flex justifyContent="center" alignItems="center" height="100%">
              <span>{title}</span>
            </Flex>
          </NavLink>
        )}
      </Button>
    )
  }

  return null
}

export default CTA
