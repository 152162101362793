import styled from 'styled-components'
//
import { Media } from '~utils'
import { StyledLinksList } from '../../social/Styled'

// eslint-disable-next-line import/prefer-default-export
export const StyledTextBlock = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul:not(${StyledLinksList}) {
    max-width: 660px;
    ${Media({
      margin: ['0 24px', null, '0 auto', '0 auto']
    })}
  }

  p {
    margin-bottom: 40px;
    line-height: 32px;
  }

  h1,
  h2,
  h3,
  h4 {
    ${Media({
      marginBottom: ['16px', '20px', '20px', '20px'],
      paddingTop: ['16px', '10px', '10px', '38px']
    })}
  }

  ul,
  ol {
    ${Media({
      marginBottom: ['10px', null, '20px']
    })}

    li {
      margin-left: 20px;
    }
  }
`
