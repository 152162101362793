import React from 'react'
//
import { useCurrentBreakpoint } from '~utils/hooks'
import { mediumBreakpoint } from '~utils/breakpoints'
import { Grid, Cell } from '../base-components/grid'
import Carousel, { CarouselProps } from '../carousel'

type CardListProps = CarouselProps

const CardList = ({ id, contents, backgroundColor, Card }: CardListProps) => {
  if (!contents || !contents.length) {
    return null
  }

  const currentBreakpoint = useCurrentBreakpoint()
  const renderCarousel = currentBreakpoint <= mediumBreakpoint

  return renderCarousel ? (
    <Carousel id={id} contents={contents} Card={Card} backgroundColor={backgroundColor} />
  ) : (
    <Grid justify="flex-start">
      {contents?.map((item: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Cell key={`${item?.id}-${index}`} cols={[11, null, 9, 2.666666666666667]} offset={[null, null, null, 1]}>
          <Card item={item} />
        </Cell>
      ))}
    </Grid>
  )
}

export default CardList
