import React from 'react'
import { useIntl } from 'react-intl'
//
import { Flex } from 'rebass'
import { getSourceImage, ImageFluid } from '~utils/image'
import {
  StyledBaristaCardContainer,
  StyledBaristaCardTextContainer,
  StyledCategoryTitle,
  StyledBaristaCardTitle,
  StyledBaristaCardSignature,
  StyledBaristaCardName,
  StyledBaristaCardLocation
} from './Styled'
import CTA from '../../base-components/cta'
import { buildSlug, NavLink } from '~utils/link'

type Props = {
  item: any
}

const BaristaCard = ({ item }: Props) => {
  const intl = useIntl()
  const parsedImage = getSourceImage(item?.image)
  const to = buildSlug(item)

  return (
    <StyledBaristaCardContainer>
      <NavLink to={to}>
        <>
          {parsedImage && <ImageFluid image={parsedImage} alt={item?.title || ''} />}
          <StyledBaristaCardTextContainer>
            <StyledCategoryTitle>{item?.categories?.[0]?.title}</StyledCategoryTitle>
            <StyledBaristaCardTitle>{item?.shortDescription?.shortDescription}</StyledBaristaCardTitle>
            <StyledBaristaCardSignature>{item?.signature}</StyledBaristaCardSignature>
            <StyledBaristaCardName>{item?.title}</StyledBaristaCardName>
            <StyledBaristaCardLocation>{item?.location}</StyledBaristaCardLocation>
            <Flex justifyContent="center">
              <CTA
                title={intl.formatMessage({ id: 'listingBlock.baristaCta' })}
                content={item}
                theme="black"
                fullWidth
                hollow
                noLink
              />
            </Flex>
          </StyledBaristaCardTextContainer>
        </>
      </NavLink>
    </StyledBaristaCardContainer>
  )
}

export default BaristaCard
